import { Q } from "@nozbe/watermelondb"
import { database } from "../model/database"
import type FeatureEvent from "../model/FeatureEvent"
import { TableName } from "../model/schema"
import { updateWatermelonDBObject } from "../utils/data.util"

/**
 * CRUD Operations for Feature events using WatermelonDB
 */
class FeatureEventService {
  // CREATE
  async add(payload: any) {
    if (payload?.eventType === "activate") {
      const featureEventsCollection = database.collections.get<FeatureEvent>(TableName.FeatureEvent)

      const lastFeatureEvent = await featureEventsCollection
        .query(Q.where("createdBy", payload.createdBy), Q.sortBy("created_at", Q.desc), Q.take(1))
        .fetch()

      if (lastFeatureEvent.length > 0) {
        const { featureKey } = lastFeatureEvent[0]
        if (featureKey === payload.featureKey) {
          return
        }
      }
    }

    await database.write(async () => {
      await database.collections.get<FeatureEvent>(TableName.FeatureEvent).create((featureEvent) => {
        updateWatermelonDBObject(featureEvent, payload)
      })
    })
  }

  // READ
  async get(id: number): Promise<FeatureEvent> {
    const featureEvent = await database.collections.get<FeatureEvent>(TableName.FeatureEvent).find(id.toString())

    if (!featureEvent) {
      throw new Error("Feature event not found")
    }

    return featureEvent
  }

  // READ
  async getAll(): Promise<FeatureEvent[]> {
    const featureEvents = await database.collections
      .get<FeatureEvent>(TableName.FeatureEvent)
      .query(Q.where("deletedAt", Q.eq(null)))
      .fetch()
    return featureEvents
  }

  // UPDATE
  async update(payload: FeatureEvent): Promise<FeatureEvent> {
    await database.write(async () => {
      const featureEvent = await database.collections
        .get<FeatureEvent>(TableName.FeatureEvent)
        .find(payload.id?.toString() || "")

      await featureEvent.update((featureEvent) => {
        updateWatermelonDBObject(featureEvent, payload)
      })
    })
    return payload
  }

  // DELETE
  async delete(id: number): Promise<string> {
    const featureEvent = await database.collections
      .get<FeatureEvent>(TableName.FeatureEvent)
      .query(Q.where("eventId", id))
      .fetch()

    if (featureEvent.length === 0) {
      throw new Error("Project not found")
    }

    await featureEvent[0].markAsDeleted()
    return "deleted"
  }
}

export const featureEventService = new FeatureEventService()
