import { Model } from "@nozbe/watermelondb"
import { field, readonly, date } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"
import type { Associations } from "@nozbe/watermelondb/Model"

export default class FeatureEvent extends Model {
  static table = TableName.FeatureEvent

  static associations: Associations = {
    // Add associations if FeatureEvent has relationships with other tables
    // For example:
    // [TableName.Feature]: { type: "belongs_to", key: "featureId" },
    // [TableName.Point]: { type: "belongs_to", key: "pointId" },
  }

  @field("eventId") eventId!: number
  @field("eventType") eventType?: string
  @field("featureId") featureId?: number
  @field("featureKey") featureKey?: string
  @field("pointId") pointId?: number
  @field("pointKey") pointKey?: string

  @readonly @date("created_at") createdAt!: number
  @field("createdBy") createdBy?: string
  @field("createdOffset") createdOffset?: number

  // You might want to add these fields for consistency with other models
  @readonly @date("updated_at") updatedAt!: number
}
