import { Relation, Model } from "@nozbe/watermelondb"
import { field, readonly, date, relation } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"
import type { Associations } from "@nozbe/watermelondb/Model"
import type Device from "./Device"
import type Feature from "./Feature"

export default class Point extends Model {
  static table = TableName.Point

  static associations: Associations = {
    [TableName.Device]: { type: "belongs_to", key: "deviceKey" },
    [TableName.Feature]: { type: "belongs_to", key: "featureKey" },
  }

  @field("pointId") pointId!: number
  @field("featureId") featureId?: number
  @field("featureKey") featureKey?: string
  @field("geom") geom?: string // Geometry type handling might need custom methods
  @field("deviceId") deviceId?: number
  @field("deviceKey") deviceKey?: string
  @field("projectId") projectId?: number
  @field("externalSurveyId") externalSurveyId?: string
  @field("fid") fid?: string
  @field("rawData") rawData?: string
  @field("latitude") latitude?: number
  @field("longitude") longitude?: number
  @field("geoJson") geoJson?: string
  @readonly @field("geomAsText") geomAsText?: string
  @field("createdBy") createdBy?: string
  @field("createdOffset") createdOffset?: number
  @field("updatedBy") updatedBy?: string
  @field("updatedOffset") updatedOffset?: number
  @field("deletedBy") deletedBy?: string
  @field("deletedOffset") deletedOffset?: number
  @readonly @date("deletedAt") deletedAt?: Date
  @readonly @date("created_at") createdAt!: number
  @readonly @date("updated_at") updatedAt!: number
  @relation(TableName.Device, "deviceKey") device?: Relation<Device>
  @relation(TableName.Feature, "featureKey") feature?: Relation<Feature>
}
