import React, { useEffect, useCallback, useState, useRef, useContext } from "react"
import type { GeoJSONProps } from "react-leaflet"
import { LayerGroup, Marker, Polygon, Polyline, Popup, Tooltip, useMap } from "react-leaflet"
import type { LatLngExpression, LeafletEvent, PathOptions } from "leaflet"
import L from "leaflet"

import type { IFormTemplate } from "../../../interfaces"
import "./MapLayer.styles.css"
import { changeSvgColor, svgToDataURL } from "../../../utils/image.util"
import type { Geometry, Properties, Feature, GeoJSONObject, LineString, MultiLineString } from "@turf/helpers"
import TextPath from "react-leaflet-textpath"
import { Button, Divider, Stack, styled, type Theme, useMediaQuery } from "@mui/material"
import FeatureProperties from "../FeatureActionSheet/FeatureProperties"
import { HiArrowsExpand, HiOutlineTrash } from "react-icons/hi"
import { PiPath } from "react-icons/pi"
import { convertCoordinates } from "../../../utils/geo.util"
import { v4 } from "uuid"
import MarkerClusterGroup from "./MakeClusterGroup"
import LayoutContext from "../../../contexts/layout.context"
import { HiPencil } from "react-icons/hi2"
import FeatureDetail from "../../../pages/Features/FeatureDetail.page"
import { featureService } from "../../../services"
import SettingsContext from "../../../contexts/settings.context"
// import { MarkerClusterGroup } from 'react-leaflet-markercluster';

interface GeoJsonData {
  type: string
  features: Feature<Geometry, Properties>[]
}

interface Props extends GeoJSONProps {
  onSelect: (form: IFormTemplate) => void
  onFeatureSelect: (feature: Feature) => void
  onFeatureEvent?: (type: string, feature: Feature) => void
  layerId: string
  layerName: string
  isDragging: boolean
  onEdit?: any
  onDragStart?: (feature: Feature) => void
  onDragEnd?: (feature: Feature) => void
  onDelete?: (feature: Feature) => void
  hiddenPointId?: any
  excludedProps?: string[]
  zoomMap?: boolean
  setZoomMap?: (value: boolean) => void
}

const MapLayer = ({
  data,
  layerId,
  onEdit,
  onDragStart,
  onFeatureSelect,
  onFeatureEvent,
  onDragEnd,
  onDelete,
  hiddenPointId,
  excludedProps,
  ...props
}: Props) => {
  const map = useMap()
  const json = L.geoJSON(data)
  const [geoObject, setGeoObject] = useState<GeoJSONObject | Feature[]>(data)
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  const markerRef = useRef<L.Marker>(null)
  const popupRef = useRef<L.Popup>(null)
  const layerRef = useRef<L.LayerGroup>(null)
  const [selectedMarker, setSelectedMarker] = useState<string>()
  const [isDragging, setIsDragging] = useState(props.isDragging)
  const southWest = L.latLng(24.396308, -125.0)
  const northEast = L.latLng(49.384358, -66.93457)
  const usaBounds = L.latLngBounds(southWest, northEast)
  const { openSidebar, layoutState } = useContext(LayoutContext)
  const [currentZoom, setCurrentZoom] = useState<number>(0)
  const { settingsState } = useContext(SettingsContext)

  const getVisibleData = (data: any, hiddenPointId: any) => {
    if (Array.isArray(data) && data.some((item) => item.type === "Feature")) {
      return data
        .filter((feature) => {
          return hiddenPointId === undefined || feature.properties.pointId !== hiddenPointId
        })
        .map((feature: Feature<Geometry, Properties>) => {
          return {
            ...feature,
            id: v4(),
          }
        })
    } else if (data && data.features !== undefined && Array.isArray(data.features)) {
      return data.features
        .filter((feature: Feature<Geometry, Properties>) => {
          return hiddenPointId === undefined || feature.properties?.pointId !== hiddenPointId
        })
        .map((feature: Feature<Geometry, Properties>) => {
          return {
            ...feature,
            id: v4(),
          }
        })
    }

    return data
  }

  useEffect(() => {
    setIsDragging(props.isDragging)
  }, [props.isDragging])

  const visibleData = getVisibleData(data, hiddenPointId)

  const editFeature = (lat: number, lng: number, pointId: any) => {
    // const currentZoom = map.getZoom()
    //map.setView([lat, lng], currentZoom)
    onEdit(pointId)
  }

  useEffect(() => {
    if (data) setGeoObject(visibleData)
  }, [data, layoutState.sidebarOpen])

  useEffect(() => {
    // Initial loading
    map.fitBounds(usaBounds)
  }, [])

  useEffect(() => {
    if (json.getLayers().length > 0 && !isDragging) {
      const bounds = json.getBounds()
      if (bounds.isValid()) {
        map.fitBounds(bounds)
      }
    }
  }, [json.getLayers().length])

  useEffect(() => {
    map.on("zoom", (e) => {
      const zoom = map.getZoom()
      setCurrentZoom(zoom)
    })

    return () => {
      map.off("zoom")
    }
  }, [map])

  const handleOnMove = useCallback(
    (feature: Feature<Geometry>) => {
      setSelectedMarker(feature.id?.toString() as string)
      popupRef.current?.close()
      if (onDragStart) {
        onDragStart(feature)
      }
      setIsDragging(true)
      // Center point...
      if (feature.geometry.coordinates && feature.geometry.coordinates.length > 1) {
        const lat = feature.geometry.coordinates[1] as number
        const long = feature.geometry.coordinates[0] as number
        map.flyTo([lat, long])
      }
    },
    [popupRef.current],
  )

  const handleOnDelete = useCallback(
    (feature: Feature<Geometry>) => {
      popupRef.current?.close()
      if (onDelete) {
        onDelete(feature)
      }
    },
    [popupRef.current],
  )

  const markerClassName = (isSelected: boolean): string => {
    return isSelected ? "map-marker-selected" : "map-marker-default"
  }

  const openFeatureDetailSidebar = async (feature: Feature<Geometry>) => {
    if (!feature.properties?.FeatureId) return
    const _feature = await featureService.getFeature(feature.properties?.FeatureId)
    openSidebar(
      <FeatureDetail
        featureId={_feature.featureId ?? 0}
        survey={{ surveyId: _feature.surveyId } as ISurvey}
        onSave={async () => {
          // await fetchFeatures()
        }}
        feature={_feature}
      />,
      "full",
    )
  }

  const renderPopupContent = (feature: Feature<Geometry>) => {
    if (feature.properties) {
      return (
        <Stack gap={1}>
          <FeatureProperties feature={feature} excludedProps={excludedProps} isEditing={false} />
          {(onFeatureEvent || onDragStart || onDelete) && <Divider />}
          <Stack justifyContent={"flex-end"} direction={"row"} gap={0.2}>
            {feature.properties?.FeatureId && (
              <Button
                size="small"
                disableElevation
                onClick={async () => {
                  await openFeatureDetailSidebar(feature)
                }}
                sx={{ textTransform: "capitalize", gap: 0.5, borderRadius: 20, px: 1 }}>
                <HiPencil />
                Edit
              </Button>
            )}
            {onFeatureEvent && (
              <>
                <Button
                  size="small"
                  disableElevation
                  sx={{ textTransform: "capitalize", gap: 0.5, borderRadius: 20, px: 1 }}
                  onClick={() => onFeatureEvent("start", feature)}>
                  <PiPath size={16} style={{ transform: "rotate(180deg)" }} />
                  Start
                </Button>
                <Button
                  size="small"
                  disableElevation
                  sx={{ textTransform: "capitalize", gap: 0.5, borderRadius: 20, px: 1 }}
                  onClick={() => onFeatureEvent("end", feature)}>
                  <PiPath size={16} />
                  End
                </Button>
              </>
            )}
            {onDragStart && (
              <Button
                size="small"
                disableElevation
                onClick={() => handleOnMove(feature)}
                sx={{ textTransform: "capitalize", gap: 0.5, borderRadius: 20, px: 1 }}>
                <HiArrowsExpand />
                Move
              </Button>
            )}
            {onDelete && (
              <Button
                size="small"
                color="error"
                disableElevation
                sx={{ textTransform: "capitalize", gap: 0.5, borderRadius: 20, px: 1 }}
                onClick={() => handleOnDelete(feature)}>
                <HiOutlineTrash />
                Delete
              </Button>
            )}
          </Stack>
        </Stack>
      )
    }
  }

  const parseCoordinateString = (str: string | undefined, defaultValue: [number, number]): [number, number] => {
    if (!str) return defaultValue
    const coords = str.split(",").map(Number)
    return coords.length === 2 && !coords.some(isNaN) ? [coords[0], coords[1]] : defaultValue
  }

  const renderCustomIcon = (
    properties: Properties,
    isSelected: boolean,
  ): L.Icon<L.IconOptions> | L.DivIcon | undefined => {
    let iconUrl = properties?.Icon
    if (properties && iconUrl) {
      // Check is feature icon is svg format
      if (properties && properties.Icon && properties.Icon.toLowerCase().includes("svg")) {
        // Update svg to feature properties
        const coloredSvg = changeSvgColor(properties?.Icon, properties?.IconColor ? properties?.IconColor : "#111")
        iconUrl = svgToDataURL(coloredSvg)
      }

      // const zoom = map.getZoom()
      // console.log("MapLayer -> CustomIcon -> Zoom -> " + zoom)

      // Default sizes
      const defaultIconSize: [number, number] = isSelected ? [24, 24] : [16, 16]
      const initialIconSize: [number, number] = parseCoordinateString(properties.IconSize, defaultIconSize)
      const selectedIconSize: [number, number] = [48, 48]
      // const zoomIconSize: [number, number] = currentZoom > 12 ? [currentZoom * 2, currentZoom * 2] : initialIconSize

      const zoomIconSize = calculateZoomBasedIconSize(currentZoom, initialIconSize, isSelected, selectedIconSize)

      // Parse the dynamic properties with fallbacks
      const iconSize = isSelected ? selectedIconSize : zoomIconSize
      const iconAnchor = parseCoordinateString(properties.IconAnchor, [iconSize[0] / 2, iconSize[1] / 2])
      const popupAnchor = parseCoordinateString(properties.PopupAnchor, [0, -iconSize[1] / 2])
      const tooltipAnchor = parseCoordinateString(properties.TooltipAnchor, [4, 0])

      return L.icon({
        iconUrl, // Replace with your icon path
        iconSize, // Set the size of the icon
        iconAnchor, // The point of the icon which will correspond to marker's location
        popupAnchor, // Point from which the popup should open relative to the iconAnchor
        tooltipAnchor,
      })
    }
    return L.divIcon({
      className: markerClassName(isSelected),
      iconSize: isSelected ? [24, 24] : [18, 18],
    })
  }

  const style = (feature: Feature): PathOptions => {
    if (
      feature.geometry.type === "LineString" ||
      feature.geometry.type === "MultiLineString" ||
      feature.geometry.type === "Polygon" ||
      feature.geometry.type === "MultiPolygon"
    ) {
      return {
        color: feature.properties?.color || "#3388ff", // Default line color
        weight: feature.properties?.weight || 3, // Default line weight
        opacity:
          (feature.properties?.opacity && feature.properties?.opacity <= 1
            ? feature.properties?.opacity
            : feature.properties?.opacity / 100) || 1, // Default line opacity
        dashArray: feature.properties?.dashArray || null, // Default line dash pattern
      }
    }
    return {} // Default style for other types
  }

  const calculateZoomBasedIconSize = (
    currentZoom: number,
    initialSize: [number, number],
    isSelected: boolean,
    selectedSize: [number, number] = [48, 48],
    minZoom: number = 12,
    maxZoom: number = 22,
  ): [number, number] => {
    // Return initial size if below minimum zoom
    if (currentZoom <= minZoom) {
      return initialSize
    }

    // Define maximum size based on selection state
    const maxSize = isSelected ? selectedSize : [Math.round(selectedSize[0] * 0.75), Math.round(selectedSize[1] * 0.75)]

    // Calculate scale factor based on zoom level
    const zoomRange = maxZoom - minZoom
    const currentPosition = Math.min(currentZoom - minZoom, zoomRange)
    const scaleFactor = (currentPosition / zoomRange) * 1.5 + 0.5

    // Apply scaling to width and height independently
    const width = Math.min(Math.round(initialSize[0] * scaleFactor), maxSize[0])
    const height = Math.min(Math.round(initialSize[1] * scaleFactor), maxSize[1])

    return [width, height]
  }

  const renderLineString = (feature: Feature<Geometry, Properties>, key?: string) => {
    // The lat and long are reversed in the data,
    // So we need to reverse accordingly.
    const coordinates = convertCoordinates(feature as Feature<LineString | MultiLineString>)

    const baseEventHandlers = {
      click: (_event: LeafletEvent) => {
        if (!isDesktop) onFeatureSelect(feature)
        else {
          _event.target.openPopup()
        }
      },
    }

    if (settingsState.projectMapFilter.selectedPropsOnHover) {
      baseEventHandlers.mouseover = (_event: LeafletEvent) => {
        if (isDesktop) {
          _event.target.openPopup()
        }
      }
    }

    return feature.properties?.lineLabel && feature.properties?.lineLabel !== "" ? (
      <TextPath
        key={key}
        text={`     ${feature.properties?.lineLabel}     `}
        repeat
        attributes={{ fill: feature.properties?.color || "#3388ff" }}
        eventHandlers={baseEventHandlers}
        pathOptions={style(feature)}
        positions={coordinates as LatLngExpression[]}>
        {isDesktop && (
          <Popup closeOnClick={false} minWidth={320}>
            {renderPopupContent(feature)}
          </Popup>
        )}
      </TextPath>
    ) : (
      <Polyline
        key={key}
        eventHandlers={baseEventHandlers}
        pathOptions={style(feature)}
        positions={coordinates as LatLngExpression[]}>
        {isDesktop && (
          <Popup closeOnClick={false} minWidth={320}>
            {renderPopupContent(feature)}
          </Popup>
        )}
      </Polyline>
    )
  }

  const renderPolygon = (feature: Feature<Geometry, Properties>, key?: string) => {
    // The lat and long are reversed in the data,
    // So we need to reverse accordingly.
    const coordinates = convertCoordinates(feature as Feature<LineString | MultiLineString>)

    const baseEventHandlers = {
      click: (_event: LeafletEvent) => {
        if (!isDesktop) onFeatureSelect(feature)
        else {
          _event.target.openPopup()
        }
      },
    }

    if (settingsState.projectMapFilter.selectedPropsOnHover) {
      baseEventHandlers.mouseover = (_event: LeafletEvent) => {
        if (isDesktop) {
          _event.target.openPopup()
        }
      }
    }

    return (
      <Polygon
        key={key}
        eventHandlers={baseEventHandlers}
        pathOptions={style(feature)}
        positions={coordinates as LatLngExpression[]}>
        {isDesktop && (
          <Popup closeOnClick={false} minWidth={320}>
            {renderPopupContent(feature)}
          </Popup>
        )}
      </Polygon>
    )
  }

  const renderPoint = (feature: Feature<Geometry, Properties>, key?: string) => {
    if (feature.geometry && feature.properties) {
      const { coordinates } = feature.geometry

      const baseEventHandlers = {
        dragend: () => {
          const marker = markerRef.current as unknown as L.Marker
          if (marker !== null) {
            editFeature(marker.getLatLng().lat, marker.getLatLng().lng, feature.properties?.pointId)
          }
        },
        click: (_event: LeafletEvent) => {
          if (!isDragging) {
            setSelectedMarker(feature.id?.toString())
            if (!isDesktop) onFeatureSelect(feature)
            else {
              _event.target.openPopup()
            }
          }
        },
      }

      if (settingsState.projectMapFilter.selectedPropsOnHover) {
        baseEventHandlers.mouseover = (_event: LeafletEvent) => {
          if (isDesktop) {
            _event.target.openPopup()
          }
        }
      }

      // Helper function to create a Marker for given coordinates
      const createMarker = (coords: number[], keySuffix: string) => {
        let toolTip: string | null = null
        if (feature?.properties?.FID) {
          toolTip = feature?.properties?.FID
        } else if (feature?.properties?.fid) {
          toolTip = feature?.properties?.fid
        } else if (feature?.properties?.metadata?.FID) {
          toolTip = feature?.properties?.metadata?.FID
        }

        return (
          <Marker
            key={`${key}-${keySuffix}`}
            ref={markerRef}
            eventHandlers={baseEventHandlers}
            icon={renderCustomIcon(feature.properties, selectedMarker === feature.id?.toString())}
            position={[coords[1], coords[0]] as LatLngExpression}>
            {toolTip && currentZoom >= 18 && (
              <Tooltip permanent direction="bottom" offset={[-8, 2]} className="map-tooltip">
                {toolTip}
              </Tooltip>
            )}

            {isDesktop && !isDragging && (
              <Popup closeOnClick={false} minWidth={320} ref={popupRef}>
                {renderPopupContent(feature)}
              </Popup>
            )}
          </Marker>
        )
      }

      // Check if coordinates is a multi-point geometry
      if (Array.isArray(coordinates[0])) {
        // Multi-point geometry, render multiple markers
        return coordinates.map((pointCoords, index) => createMarker(pointCoords as number[], index.toString()))
      } else {
        // Single-point geometry, render a single marker
        return createMarker(coordinates as number[], "0")
      }
    }
    return null
  }

  const renderFeatureType = (feature: Feature<Geometry, Properties>, key?: string) => {
    switch (feature.geometry.type) {
      case "Point":
      case "MultiPoint":
        return renderPoint(feature, key)
      case "LineString":
      case "MultiLineString":
        return renderLineString(feature, key)
      case "Polygon":
      case "MultiPolygon":
        return renderPolygon(feature, key)
    }
  }

  const renderFeatures = (feature: Feature | GeoJsonData, index?: number) => {
    const singleFeature = feature as Feature<Geometry, Properties>
    const featureCollection = feature as GeoJsonData
    if (singleFeature && singleFeature.geometry) {
      return renderFeatureType(singleFeature, String(index))
    }

    if (featureCollection && featureCollection.features) {
      return featureCollection.features.map((feature, collectionIndex) =>
        renderFeatureType(feature, `${collectionIndex}_${index}`),
      )
    }
  }
  const featuresArray = geoObject as Feature[]
  if (
    featuresArray &&
    featuresArray.length > 0 &&
    (featuresArray[0].geometry.type === "Point" || featuresArray[0].geometry.type === "MultiPoint")
  ) {
    return (
      <LayerGroup ref={layerRef} key={layerId}>
        <MarkerClusterGroup disableClusteringAtZoom={19}>
          {Array.isArray(featuresArray) && featuresArray.length > 0
            ? featuresArray.map((feature, index) => renderFeatures(feature, index))
            : renderFeatures(geoObject as Feature)}
        </MarkerClusterGroup>
      </LayerGroup>
    )
  } else {
    return (
      <LayerGroup ref={layerRef} key={layerId}>
        {Array.isArray(featuresArray) && featuresArray.length > 0
          ? featuresArray.map((feature, index) => renderFeatures(feature, index))
          : renderFeatures(geoObject as Feature)}
      </LayerGroup>
    )
  }
}

export default React.memo(MapLayer)
