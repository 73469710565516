import { Model } from "@nozbe/watermelondb"
import { field, readonly, date } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"
import type { Associations } from "@nozbe/watermelondb/Model"

export default class LineStyle extends Model {
  static table = TableName.LineStyle

  static associations: Associations = {
    [TableName.Feature]: { type: "has_many", foreignKey: "lineStyleKey" },
  }

  @field("lineStyleId") lineStyleId!: number
  @field("lineStyleName") lineStyleName?: string
  @field("color") color?: string
  @field("weight") weight?: string
  @field("opacity") opacity?: string
  @field("lineCap") lineCap?: string
  @field("lineJoin") lineJoin?: string
  @field("lineLabel") lineLabel?: string
  @field("dashArray") dashArray?: string
  @field("dashOffset") dashOffset?: number
  @field("thumbnail") thumbnail?: string
  @readonly @date("created_at") createdAt!: number
  @field("createdBy") createdBy?: string
  @field("createdOffset") createdOffset?: number
  @readonly @date("updated_at") updatedAt?: number
  @field("updatedBy") updatedBy?: string
  @field("updatedOffset") updatedOffset?: number
  @readonly @date("deletedAt") deletedAt?: number
  @field("deletedBy") deletedBy?: string
  @field("deletedOffset") deletedOffset?: number
}
