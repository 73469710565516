import type { IWorkType } from "../interfaces"
import { database } from "../model/database"
import { TableName } from "../model/schema"
import { Q } from "@nozbe/watermelondb"
import type WorkType from "../model/WorkType"
import { updateWatermelonDBObject } from "../utils/data.util"

/**
 * CRUD Operations for WorkTypes using WatermelonDB
 */
class WorkTypeService {
  // CREATE
  async addWorkType(payload: IWorkType): Promise<IWorkType> {
    await database.write(async () => {
      const newWorkType = await database.collections.get<WorkType>(TableName.WorkType).create((workType) => {
        updateWatermelonDBObject(workType, payload)
      })

      if (newWorkType) {
        const workType = this.mapWorkType(newWorkType)
        return workType
      }
    })
    return payload
  }

  // READ
  async getWorkTypes(): Promise<IWorkType[]> {
    const query = database.collections.get<WorkType>(TableName.WorkType).query(Q.where("deletedAt", Q.eq(null)))

    const workTypes = await query.fetch()

    return workTypes.map((workType) => this.mapWorkType(workType))
  }

  // READ
  async getWorkType(id: string): Promise<IWorkType> {
    const workType = await database.collections.get<WorkType>(TableName.WorkType).find(id)

    if (workType === null) {
      throw new Error("WorkType not found")
    }

    return this.mapWorkType(workType)
  }

  async getWorkTypeById(id: number): Promise<IWorkType> {
    const query = database.collections.get<WorkType>(TableName.WorkType).query(Q.where("workTypeId", id))

    const workType = await query.fetch()
    console.log(workType)

    if (workType.length === 0) {
      throw new Error("WorkType not found")
    }

    return this.mapWorkType(workType[0])
  }

  // UPDATE
  async updateWorkType(payload: IWorkType): Promise<IWorkType> {
    await database.write(async () => {
      const workType = await database.collections.get<WorkType>(TableName.WorkType).find(payload.id ?? "")

      await workType.update((workType) => {
        updateWatermelonDBObject(workType, payload)
      })
    })
    return payload
  }

  // DELETE
  async deleteWorkType(id: string): Promise<string> {
    const workType = await database.collections.get<WorkType>(TableName.WorkType).find(id ?? "")

    if (workType === null) {
      throw new Error("Project not found")
    }

    await workType.markAsDeleted()
    return "deleted"
  }

  /**
   * Converts WatermelonDB type to interface type
   *
   * @param workType
   * @returns IWorkType
   */
  private mapWorkType(workType: WorkType): IWorkType {
    return {
      workTypeId: workType.workTypeId,
      workTypeName: workType.workTypeName,
      workTypeDescription: workType.workTypeDescription,
      sortOrder: workType.sortOrder,
      locationRequired: workType.locationRequired,
      locationDisabled: workType.locationDisabled,
      locationHidden: workType.locationHidden,
      icon: workType.icon,
      createdAt: workType.createdAt,
      createdBy: workType.createdBy,
    }
  }
}

export const workTypeService = new WorkTypeService()
