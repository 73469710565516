import type { IPermission, IUser } from "@utilisourcepackagelibdev/utilisourcepackagelib"
import { Q } from "@nozbe/watermelondb"
import { database } from "../model/database"
import type User from "../model/User"
import { TableName } from "../model/schema"
import { updateWatermelonDBObject } from "../utils/data.util"

class UserService {
  async login(onSuccess?: Function, onError?: Function) {
    // Assuming login logic will be handled separately, as it often requires external API interaction.
    if (onSuccess) onSuccess()
    else if (onError) onError(new Error("Login logic not implemented."))
  }

  async getAll(): Promise<IUser[]> {
    const users = await database.collections
      .get<User>(TableName.User)
      .query(Q.where("deletedAt", Q.eq(null)))
      .fetch()
    return users.map((user) => this.mapUser(user))
  }

  async addUser(payload: IUser): Promise<IUser> {
    await database.write(async () => {
      await database.collections.get<User>(TableName.User).create((user) => {
        updateWatermelonDBObject(user, payload)
      })
    })
    return payload
  }

  async updateUser(payload: IUser): Promise<IUser> {
    await database.write(async () => {
      const user = await database.collections.get<User>(TableName.User).find(payload.id || "")
      await user.update((user) => {
        updateWatermelonDBObject(user, payload)
      })
    })
    return payload
  }

  async updateUserSurvey(id: string): Promise<IUser> {
    const storedUser: string | null = localStorage.getItem("user")
    const currentUser: IUser | null = storedUser && JSON.parse(storedUser)

    const user = await database.write(async () => {
      const userToUpdate = await database.collections
        .get<User>(TableName.User)
        .query(Q.where("email", currentUser?.email ?? ""))
        .fetch()
      if (userToUpdate.length === 0) {
        throw new Error("User not found")
      }
      return userToUpdate[0].update((record) => {
        record.surveyKey = id
      })
    })
    return this.mapUser(user)
  }

  async updateUserFeature(userName?: string, featureKey?: string) {
    const userToUpdate = await database.collections
      .get<User>(TableName.User)
      .query(Q.where("userName", userName ?? ""))
      .fetch()
    if (userToUpdate.length > 0) {
      await userToUpdate[0].setFeature(featureKey)
    }
  }

  async updateUserDevice(userName?: string, deviceId?: number) {
    const userToUpdate = await database.collections
      .get<User>(TableName.User)
      .query(Q.where("userName", userName ?? ""))
      .fetch()
    if (userToUpdate.length > 0) {
      await userToUpdate[0].setDeviceId(deviceId)
    }
  }

  async getUser(id: string): Promise<IUser> {
    const user = await database.collections.get<User>(TableName.User).find(id)
    if (!user) {
      throw new Error("User not found")
    }
    return this.mapUser(user)
  }

  private lowercaseKeys(obj: Record<string, any>): IPermission {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key.toLowerCase(), value])) as IPermission
  }

  private mapPermissions(permissions: string): IPermission[] {
    const array = JSON.parse(permissions)
    return array.map(this.lowercaseKeys)
  }

  private mapUser(user: User): IUser {
    console.log(user)
    return {
      userId: user.userId,
      userName: user.userName,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      roleId: user.roleId,
      roleName: user.roleName,
      surveyId: user.surveyId,
      externalId: user.externalId || "",
      deviceId: user.deviceId,
      createdAt: user.createdAt,
      createdBy: user.createdBy,
      updatedAt: user.updatedAt,
      updatedBy: user.updatedBy,
      deletedAt: user.deletedAt,
      deletedBy: user.deletedBy,
      permissions: user.permissionsData ? this.mapPermissions(user.permissionsData) : [],
    }
  }
}

export const userService = new UserService()
