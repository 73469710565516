import { Model } from "@nozbe/watermelondb"
import { field, readonly, date } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"
import type { Associations } from "@nozbe/watermelondb/Model"

export default class MapSymbol extends Model {
  static table = TableName.MapSymbol

  static associations: Associations = {
    [TableName.Feature]: { type: "has_many", foreignKey: "mapSymbolKey" },
  }

  @field("mapSymbolId") mapSymbolId!: number
  @field("mapSymbolName") mapSymbolName?: string
  @field("icon") icon?: string
  @field("shadowIcon") shadowIcon?: string
  @field("iconSize") iconSize?: string
  @field("iconColor") iconColor?: string
  @field("shadowSize") shadowSize?: string
  @field("iconAnchor") iconAnchor?: string
  @field("shadowAnchor") shadowAnchor?: string
  @field("popupAnchor") popupAnchor?: string
  @readonly @date("created_at") createdAt!: number
  @field("createdBy") createdBy?: string
  @field("createdOffset") createdOffset?: number
  @readonly @date("updated_at") updatedAt?: number
  @field("updatedBy") updatedBy?: string
  @field("updatedOffset") updatedOffset?: number
  @readonly @date("deletedAt") deletedAt?: Date
  @field("deletedBy") deletedBy?: string
  @field("deletedOffset") deletedOffset?: number
}
