import type { Feature, FeatureCollection } from "@turf/helpers"
import { point as turfPoint } from "@turf/helpers"
import type { Dayjs } from "dayjs"
import dayjs from "dayjs"
import { Q } from "@nozbe/watermelondb"
import { database } from "../model/database"
import type Point from "../model/Point"
import type { IPoint } from "../interfaces"
import type { DateRange } from "@mui/x-date-pickers-pro/models"
import type { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts"
import { TableName } from "../model/schema"
import type Device from "../model/Device"
import { updateWatermelonDBObject } from "../utils/data.util"
import { deviceService } from "./device.service"
import type { Subscription } from "rxjs"
import { map } from "rxjs"
import type { IFeatureFilterStatus } from "../interfaces/survey.interface"
import { FormDataParser } from "../utils/formDataParser.util"

class PointService {
  async getPoints(arg: {
    projectId?: number
    deviceId?: number
    surveyKey?: string
    featureKey?: string
  }): Promise<IPoint[]> {
    let query = database.collections.get<Point>(TableName.Point).query(Q.where("deletedAt", Q.eq(null)))

    if (arg.projectId) {
      query = query.extend(Q.where("projectId", arg.projectId))
    }
    if (arg.deviceId) {
      query = query.extend(Q.where("deviceId", arg.deviceId))
    }
    if (arg.surveyKey !== undefined && arg.surveyKey !== null && arg.surveyKey !== "") {
      query = query.extend(Q.on(TableName.Feature, "surveyKey", arg.surveyKey))
    }
    if (arg.featureKey) {
      query = query.extend(Q.where("featureKey", arg.featureKey))
    }

    const points = await query.fetch()
    return points
      .map(this.mapPoint)
      .filter((p) => p.geoJson && p.geoJson !== null && p.geoJson !== "")
      .map(this.processPoint)
  }

  subscribe(arg: {
    projectId?: number
    deviceId?: number
    surveyKey?: string
    featureKey?: string
    callback: (points: IPoint[]) => void
  }): Subscription {
    let query = database.collections.get<Point>(TableName.Point).query(Q.where("deletedAt", Q.eq(null)))

    if (arg.projectId) {
      query = query.extend(Q.where("projectId", arg.projectId))
    }
    if (arg.deviceId) {
      query = query.extend(Q.where("deviceId", arg.deviceId))
    }
    if (arg.surveyKey !== undefined && arg.surveyKey !== null && arg.surveyKey !== "") {
      query = query.extend(Q.on(TableName.Feature, "surveyKey", arg.surveyKey))
    }
    if (arg.featureKey) {
      query = query.extend(Q.where("featureKey", arg.featureKey))
    }

    const $points = query.observe()
    const subscription = $points
      .pipe(
        map((points) => {
          console.log("points", points)
          return points
            .map(this.mapPoint)
            .filter((p) => p.geoJson && p.geoJson !== null && p.geoJson !== "")
            .map(this.processPoint)
        }),
      )
      .subscribe((points) => arg.callback(points))

    return subscription
  }

  processPoint(p: IPoint): IPoint {
    const geoJson = JSON.parse(p.geoJson ?? "{}")
    return {
      ...p,
      createdAtDate: dayjs((p.createdAt ?? 0) * 1000).format("MM/DD/YYYY hh:mm:ss A"),
      longitude: geoJson?.geometry?.coordinates[0],
      latitude: geoJson?.geometry?.coordinates[1],
      geoFeature: turfPoint(geoJson?.geometry?.coordinates, {
        featureId: p.featureId,
        featureKey: p.featureKey,
      }),
    }
  }

  async toFeature(p: IPoint): Promise<Feature | null> {
    if (!p.geoJson) return null
    const geoJson = JSON.parse(p.geoJson) as Feature
    const point = await database.collections.get<Point>(TableName.Point).find(p.id ?? "")

    const feature = await point?.feature?.fetch()
    const form = feature?.form ? await feature.form.fetch() : null
    const formSymbol = form?.mapSymbol ? await form.mapSymbol.fetch() : null
    return {
      ...geoJson,
      properties: {
        ...geoJson.properties,
        Icon: formSymbol ? formSymbol.icon : undefined,
        IconColor: formSymbol?.iconColor ? formSymbol.iconColor : undefined,
        IconSize: formSymbol?.iconSize ? formSymbol.iconSize : undefined,
        IconAnchor: formSymbol?.iconAnchor ? formSymbol.iconAnchor : undefined,
        PopupAnchor: formSymbol?.popupAnchor ? formSymbol.popupAnchor : undefined,
      },
    }
  }

  async addPoint(payload: Omit<IPoint, "geoFeature">, source: string = "device"): Promise<IPoint> {
    if (source === "mobile") {
      const device = await deviceService.getDeviceForLocalUser()
      payload.deviceId = device.deviceId
      payload.deviceKey = device.id
    }
    await database.write(async () => {
      await database.collections.get<Point>(TableName.Point).create((point) => {
        updateWatermelonDBObject(point, payload)
      })
    })

    return payload
  }

  async getPoint(id: string): Promise<IPoint> {
    const point = await database.collections.get<Point>(TableName.Point).find(id ?? "")
    return this.mapPoint(point)
  }

  async getUsers(projectId: number): Promise<string[]> {
    const query = database.collections
      .get<Point>(TableName.Point)
      .query(Q.where("deletedAt", Q.eq(null)), Q.where("projectId", projectId))
    const points = await query.fetch()
    const users = points.map((p) => p.createdBy ?? "")

    return Array.from(new Set(users))
  }

  async updatePoint(payload: IPoint): Promise<IPoint> {
    await database.write(async () => {
      const point = await database.collections.get<Point>(TableName.Point).find(payload.id ?? "")
      await point.update((point) => {
        updateWatermelonDBObject(point, payload)
      })
    })
    return payload
  }

  async deletePoint(id: number): Promise<string> {
    await database.write(async () => {
      const point = await database.collections.get<Point>(TableName.Point).find(id.toString())
      if (!point) {
        throw new Error("Point not found")
      }
      await point.markAsDeleted()
    })
    return "deleted"
  }

  async search(arg: {
    projectId?: number
    time?: string
    deviceId?: number
    surveyKey?: string
    featureKey?: string
    userId?: number
    startAt?: number
    endAt?: number
    user?: string
  }): Promise<IPoint[]> {
    let query = database.collections.get<Point>(TableName.Point).query(Q.where("deletedAt", null))

    if (arg.projectId) {
      query = query.extend(Q.or(Q.where("projectId", arg.projectId), Q.where("projectId", null)))
    }

    if (arg.time && arg.time !== "all") {
      const offset = dayjs().utcOffset() * 60

      if (arg.time === "today") {
        const startOfDay = dayjs().startOf("day").valueOf() - offset
        const endOfDay = dayjs().endOf("day").valueOf() - offset
        query = query.extend(Q.where("created_at", Q.between(startOfDay, endOfDay)))
      } else if (arg.time === "yesterday") {
        const startOfDay = dayjs().subtract(1, "day").startOf("day").valueOf() - offset
        const endOfDay = dayjs().subtract(1, "day").endOf("day").valueOf() - offset
        query = query.extend(Q.where("created_at", Q.between(startOfDay, endOfDay)))
      } else if (arg.time === "last-week") {
        const startOfLastWeek = dayjs().subtract(1, "week").startOf("week").valueOf() - offset
        const endOfLastWeek = dayjs().subtract(1, "week").endOf("week").valueOf() - offset
        query = query.extend(Q.where("created_at", Q.between(startOfLastWeek, endOfLastWeek)))
      } else {
        const dates = arg.time.split(",")
        if (dates.length === 2) {
          const startDate = dayjs(dates[0]).startOf("day").valueOf() - offset
          const endDate = dayjs(dates[1]).endOf("day").valueOf() - offset
          query = query.extend(Q.where("created_at", Q.between(startDate, endDate)))
        } else if (dates.length === 1) {
          const startDate = dayjs(dates[0]).startOf("day").valueOf() - offset
          const endDate = dayjs(dates[0]).endOf("day").valueOf() - offset
          query = query.extend(Q.where("created_at", Q.between(startDate, endDate)))
        }
      }
    }

    if (arg.deviceId !== undefined && arg.deviceId !== null && arg.deviceId !== -1) {
      query = query.extend(Q.where("deviceId", arg.deviceId))
    }

    if (arg.featureKey !== undefined && arg.featureKey !== null && arg.featureKey !== "") {
      query = query.extend(Q.where("featureKey", arg.featureKey))
    }

    if (arg.surveyKey !== undefined && arg.surveyKey !== null && arg.surveyKey !== "") {
      query = query.extend(Q.on(TableName.Feature, "surveyKey", arg.surveyKey))
    }

    if (arg.user) {
      query = query.extend(Q.where("createdBy", arg.user))
    }

    let points = await query.fetch()
    if (arg.startAt !== undefined && arg.endAt !== undefined) {
      points = points.slice(arg.startAt, arg.endAt)
    }
    const pointsWithDeviceSerialNumber = await Promise.all(
      points
        .filter((p) => p.geoJson && p.geoJson !== null && p.geoJson !== "")
        .map(async (point) => {
          const device = point.device ? ((await point.device.fetch()) as Device) : null
          return {
            ...this.processPoint(this.mapPoint(point)),
            deviceSerialNumber: device?.deviceSerialNumber,
          }
        }),
    )

    return pointsWithDeviceSerialNumber
  }

  async getProperties(arg: {
    pointId: number
    time?: string | Date
    projectId?: number
    searchText?: string
    formId?: number
    status?: IFeatureFilterStatus
    user?: string
    surveyId?: number
  }): Promise<FeatureCollection> {
    // Query for specific point with all related data
    const points = await database.collections.get<Point>(TableName.Point).query(Q.where("pointId", arg.pointId)).fetch()

    if (!points || points.length === 0) {
      throw new Error("Point not found")
    }

    const point = points[0]
    const feature = await point?.feature?.fetch()
    const survey = await feature?.survey?.fetch()
    const project = await survey?.project?.fetch()

    // Create GeoJSON point feature with grouped properties
    const geoJsonFeature = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [point.longitude, point.latitude],
          },
          properties: {
            metadata: {
              "Feature Name": feature?.featureName,
              Description: feature?.featureDescription,
              Job: survey?.surveyName,
              Project: project?.projectName,
              Status: feature?.status,
              FeatureId: feature?.featureId,
              PointId: point.pointId,
              Latitude: point.latitude,
              Longitude: point.longitude,
              "Point Created On": dayjs(point.createdAt * 1000).format("YYYY-MM-DD"),
              "Point Created Time": dayjs(point.createdAt * 1000).format("HH:mm:ss"),
              "Point Created By": point.createdBy,
              "Feature Created On": dayjs(feature?.createdAt).format("YYYY-MM-DD"),
              "Feature Created Time": dayjs(feature?.createdAt).format("HH:mm:ss"),
              "Feature Created By": feature?.createdBy,
            },
            formData: feature?.formData ? FormDataParser.parseFormData(feature.formData) : {},
            pointData: point.rawData ? JSON.parse(point.rawData) : {},
            // Get image URLs from attachments
            images: await feature?.attachments
              .fetch()
              .then((attachments) => attachments.map((attachment) => attachment.url)),
          },
        },
      ],
    } as FeatureCollection

    return geoJsonFeature
  }

  mapPoint(p: Point): IPoint {
    return {
      pointId: p.pointId,
      featureId: p.featureId,
      featureKey: p.featureKey,
      deviceId: p.deviceId,
      projectId: p.projectId,
      rawData: p.rawData,
      fid: p.fid,
      externalSurveyId: p.externalSurveyId,
      createdAt: p.createdAt,
      createdBy: p.createdBy,
      geomAsText: p.geomAsText,
      geoJson: p.geoJson,
      createdAtDate: dayjs((p.createdAt ?? 0) * 1000).format("MM/DD/YYYY hh:mm:ss A"),
      latitude: p.latitude,
      longitude: p.longitude,
      id: p.id,
    }
  }

  newPointTimeFilters: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: "Today",
      getValue: () => {
        const today = dayjs()
        return [today.startOf("day"), today.endOf("day")]
      },
    },
    {
      label: "This Week",
      getValue: () => {
        const today = dayjs()
        return [today.startOf("week"), today.endOf("week")]
      },
    },
    {
      label: "Last Week",
      getValue: () => {
        const today = dayjs()
        const prevWeek = today.subtract(7, "day")
        return [prevWeek.startOf("week"), prevWeek.endOf("week")]
      },
    },
    {
      label: "Last 7 Days",
      getValue: () => {
        const today = dayjs()
        return [today.subtract(7, "day"), today]
      },
    },
    {
      label: "Current Month",
      getValue: () => {
        const today = dayjs()
        return [today.startOf("month"), today.endOf("month")]
      },
    },
    {
      label: "All",
      getValue: () => {
        const today = dayjs()
        const start = today.subtract(30, "years")
        const end = today.add(30, "years")
        return [start, end]
      },
    },
    { label: "Reset", getValue: () => [null, null] },
  ]

  pointTimeFilters = [
    {
      id: "today",
      name: "Today",
    },
    {
      id: "yesterday",
      name: "Yesterday",
    },
    {
      id: "last-week",
      name: "Last Week",
    },
  ]
}

export const pointService = new PointService()
