import type { IWorld } from "../interfaces/world.interface"
import type { IProject } from "../interfaces/project.interface"
import { database } from "../model/database"
import { TableName } from "../model/schema"
import { Q } from "@nozbe/watermelondb"
import type World from "../model/World"
import type Project from "../model/Project"
import { mapProject } from "./project.service"
import { updateWatermelonDBObject } from "../utils/data.util"

/**
 * CRUD Operations for Worlds using WatermelonDB
 */
class WorldService {
  // CREATE
  async addWorld(payload: IWorld): Promise<IWorld> {
    await database.write(async () => {
      const newWorld = await database.collections.get<World>(TableName.World).create((world) => {
        updateWatermelonDBObject(world, payload)
      })

      if (newWorld) {
        const world = this.mapWorld(newWorld)
        return world
      }
    })
    return payload
  }

  // READ
  async getWorlds(): Promise<IWorld[]> {
    const query = database.collections.get<World>(TableName.World).query(Q.where("deletedAt", Q.eq(null)))

    const worlds = await query.fetch()

    return worlds.map((world) => this.mapWorld(world))
  }

  // READ
  async getWorld(id: number): Promise<[IWorld, IProject[]]> {
    const query = database.collections.get<World>(TableName.World).query(Q.where("worldId", id))

    const world = await query.fetch()
    console.log(world)

    if (world.length === 0) {
      throw new Error("World not found", id)
    }

    const projects = await database.collections.get<Project>(TableName.Project).query(Q.where("worldId", id))

    return [this.mapWorld(world[0]), projects.map((project) => mapProject(project))]
  }

  // UPDATE
  async updateWorld(payload: IWorld): Promise<IWorld> {
    await database.write(async () => {
      const world = await database.collections.get<World>(TableName.World).find(payload.id?.toString() ?? "")

      await world.update((world) => {
        updateWatermelonDBObject(world, payload)
      })
    })
    return payload
  }

  // DELETE
  async deleteWorld(id: number): Promise<string> {
    const world = await database.collections.get<World>(TableName.World).query(Q.where("worldId", id)).fetch()

    if (world.length === 0) {
      throw new Error("Project not found")
    }

    await world[0].markAsDeleted()
    return "deleted"
  }

  /**
   * Converts WatermelonDB type to interface type
   *
   * @param world
   * @returns IWorld
   */
  public mapWorld(world: World): IWorld {
    return {
      worldId: world.worldId,
      worldName: world.worldName,
      clientId: world.clientId,
      createdAt: world.createdAt,
      createdBy: world.createdBy,
    }
  }
}

export const worldService = new WorldService()
