import { Relation, Query, Model } from "@nozbe/watermelondb"
import { field, readonly, date, text, relation, children } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"
import type { Associations } from "@nozbe/watermelondb/Model"
import type LineStyle from "./LineStyle"
import type MapSymbol from "./MapSymbol"
import type Survey from "./Survey"
import type Point from "./Point"
import type Attachment from "./Attachment"
import type Device from "./Device"
import type Form from "./Form"

export default class Feature extends Model {
  static table = TableName.Feature

  static associations: Associations = {
    [TableName.LineStyle]: { type: "belongs_to", key: "lineStyleKey" },
    [TableName.MapSymbol]: { type: "belongs_to", key: "mapSymbolKey" },
    [TableName.Survey]: { type: "belongs_to", key: "surveyKey" },
    [TableName.Form]: { type: "belongs_to", key: "formKey" },
    [TableName.Device]: { type: "belongs_to", key: "deviceKey" },
    [TableName.Point]: { type: "has_many", foreignKey: "featureKey" },
    [TableName.Attachment]: { type: "has_many", foreignKey: "objectKey" },
  }

  @field("featureId") featureId!: number
  @field("featureName") featureName?: string
  @field("featureDescription") featureDescription?: string
  @field("surveyId") surveyId!: number
  @field("surveyKey") surveyKey!: string
  @field("deviceId") deviceId?: number
  @field("deviceKey") deviceKey!: string
  @field("formId") formId?: number
  @field("formKey") formKey!: string
  @field("formData") formData?: string
  @field("formTitle") formTitle?: string
  @field("status") status?: string
  @text("geoJson") geoJson?: string // Store geometry data as a serialized string.
  @field("featureTypes") featureTypes?: string
  @field("workTypeName") workTypeName?: string
  @field("workTypeId") workTypeId?: number
  @field("workTypeKey") workTypeKey!: string
  @field("mapSymbolId") mapSymbolId?: number
  @field("mapSymbolKey") mapSymbolKey!: string
  @field("lineStyleId") lineStyleId?: number
  @field("lineStyleKey") lineStyleKey!: string

  @readonly @date("created_at") createdAt!: number
  @field("createdBy") createdBy?: string
  @field("createdOffset") createdOffset?: number

  @readonly @date("updated_at") updatedAt?: number
  @field("updatedBy") updatedBy?: string
  @field("updatedOffset") updatedOffset?: number

  @field("deletedAt") deletedAt?: number
  @field("deletedBy") deletedBy?: string
  @field("deletedOffset") deletedOffset?: number

  @children(TableName.Point) points!: Query<Point>
  @children(TableName.Attachment) attachments!: Query<Attachment>

  @relation(TableName.LineStyle, "lineStyleKey") lineStyle?: Relation<LineStyle>
  @relation(TableName.MapSymbol, "mapSymbolKey") mapSymbol?: Relation<MapSymbol>
  @relation(TableName.Survey, "surveyKey") survey?: Relation<Survey>
  @relation(TableName.Form, "formKey") form?: Relation<Form>
  @relation(TableName.Device, "deviceKey") device?: Relation<Device>
}
