import { Q } from "@nozbe/watermelondb"
import type { FieldDataOptions, IProject } from "../interfaces"
import { database } from "../model/database"
import type Project from "../model/Project"
import { TableName } from "../model/schema"
import { updateWatermelonDBObject } from "../utils/data.util"

/**
 * CRUD Operations for Projects using WatermelonDB
 */
class ProjectService {
  // CREATE
  async add(payload: IProject): Promise<IProject> {
    await database.write(async () => {
      const newProject = await database.collections.get<Project>(TableName.Project).create((project) => {
        updateWatermelonDBObject(project, payload)
      })

      if (newProject) {
        const project = mapProject(newProject)
        return project
      }
    })
    return payload
  }

  // READ
  async getAll(worldId?: number): Promise<IProject[]> {
    let query = database.collections.get<Project>(TableName.Project).query(Q.where("deletedAt", Q.eq(null)))
    if (worldId) {
      query = query.extend(Q.where("worldId", worldId))
    }
    const projects = await query.fetch()
    return projects.map((project) => mapProject(project))
  }

  // UPDATE
  async update(payload: IProject): Promise<IProject> {
    await database.write(async () => {
      const project = await database.collections.get<Project>(TableName.Project).find(payload.id?.toString() || "")

      await project.update((project) => {
        updateWatermelonDBObject(project, payload)
      })
    })
    return payload
  }

  // DELETE
  async delete(id: number): Promise<string> {
    const project = await database.collections.get<Project>(TableName.Project).query(Q.where("projectId", id)).fetch()

    if (project.length === 0) {
      throw new Error("Project not found")
    }

    await project[0].markAsDeleted()
    return "deleted"
  }
  async getOptions(worldId?: number): Promise<FieldDataOptions> {
    let query = database.collections.get<Project>(TableName.Project).query(Q.where("deletedAt", Q.eq(null)))
    if (worldId) {
      query = query.extend(Q.where("worldId", worldId))
    }
    const projects = await query.fetch()
    return projects.reduce((acc, project) => {
      // Assuming project has id, name, and description fields
      acc[project.id] = {
        value: project.id.toString(),
        name: project.projectName ?? "",
        description: `${project.projectNumber} - ${project.projectName}`,
        pkey: project.projectId,
      }
      return acc
    }, {} as FieldDataOptions)
  }
}

/**
 * Converts WatermelonDB type to interface type
 *
 * @param Project
 * @returns IProject
 */
export function mapProject(project: Project): IProject {
  return {
    projectId: project.projectId,
    projectNumber: project.projectNumber,
    projectName: project.projectName,
    worldId: project.worldId,
    projectType: project.projectType,
    createdAt: project.createdAt,
    createdBy: project.createdBy,
  }
}

export const projectService = new ProjectService()
