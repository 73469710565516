import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  Popover,
  Stack,
  type Theme,
  Typography,
  useMediaQuery,
  useTheme,
  Drawer,
  Badge,
} from "@mui/material"
import { HiGlobeAmericas, HiOutlineSignalSlash } from "react-icons/hi2"
import React, { useContext, useEffect, useRef, useState } from "react"
import { Close } from "@mui/icons-material"
import { MapContainer, Marker, TileLayer } from "react-leaflet"
import type { Map } from "leaflet"
import { LatLng, Icon as LeafletIcon } from "leaflet"
import useSound from "use-sound"
import { GoChecklist } from "react-icons/go"

import SettingsContext from "../../../contexts/settings.context"
import { UPDATE_SETTINGS } from "../../../contexts/reducers/settings.reducer"
import { deviceService, pointService, userService, geolocationService, logService, LogRequest } from "../../../services"
import { SignalRContext } from "../../../App"

import deviceSvgUrl from "../../../assets/svgs/gnss-antenna.svg"
import appSvgUrl from "../../../assets/images/location.png"
import ding from "../../../assets/sounds/ding.mp3"
import type { IUser, IFeatureStyled } from "../../../interfaces"
import { changeSvgColor } from "../../../utils/image.util"
import { VscTools } from "react-icons/vsc"
import { SelectProjectList, SelectSurveyList, SelectWorldList } from "../../../pages"
import { PiGpsSlashDuotone, PiMapPinLine, PiWifiHigh, PiWifiSlash } from "react-icons/pi"
import { RiGpsFill } from "react-icons/ri"
import { design } from "../../../constants/design.constants"
import FeatureSelectorSheet from "../Features/FeatureSelectorSheet.component"
import { useNavigate } from "react-router"
import { useLocalStorage } from "usehooks-ts"
import LayoutContext from "../../../contexts/layout.context"
import Profile from "../Profile/Profile.component"
import BlobCountBadge from "./BlobCountBadge"
import { v4 } from "uuid"
import { useSync } from "../../../hooks/useSync"
import { HiStatusOffline, HiStatusOnline } from "react-icons/hi"
import { BASE_API_URL } from "../../../constants/commonStrings.constant"

const StatusBar = ({ mode }: { mode: "compact" | "regular" }) => {
  const theme = useTheme()
  const { settingsState, updateSettings } = useContext(SettingsContext)
  const { openSidebar } = useContext(LayoutContext)
  const mapRef = useRef<Map>(null)
  const [pointCoords, setPointCoords] = useState<LatLng>()
  const [positionCoords, setPositionCoords] = useState<LatLng>()
  const [serialNumber, setSerialNumber] = useState<string>((settingsState.deviceSerialNumber ?? "")?.toUpperCase())
  const [deviceId, setDeviceId] = useState<number | undefined>(settingsState.deviceId)
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [signalRConnected, setSignalRConnected] = React.useState(false)
  const [step, setStep] = useState<"connect device" | "detect device" | "point received">("connect device")
  const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false)
  const [user] = useLocalStorage<IUser | null>("user", null)
  const navigate = useNavigate()
  const { isOnline, isSyncing } = useSync()

  const [soundQueue, setSoundQueue] = useState<string[]>([])
  // const [blobCount, setBlobCount] = useState(0)

  const handleSoundEnd = () => {
    if (soundQueue.length > 0) {
      setSoundQueue((prevQueue) => prevQueue.slice(1))
    }
  }

  const [playDing, { sound }] = useSound(ding, {
    // TODO: Using Vite's HotModuleReplacement in development, we are getting an error about the rate 0 being undefined.
    // See github issue: https://github.com/joshwcomeau/use-sound/issues/125 for more information.
    onend: handleSoundEnd,
    sprite: {
      ding: [0, 800],
    },
  })

  useEffect(() => {
    if (soundQueue.length > 0 && !sound.playing()) {
      playDing({ id: "ding" })
    }
  }, [soundQueue, sound, playDing])

  const addToQueue = (soundEvent: string) => {
    setSoundQueue((prevQueue: string[]) => [...prevQueue, soundEvent])
  }

  // set the current position when you open the app
  const setCurrentPosition = () => {
    geolocationService.getCurrentLocation((position) => {
      if (position && position.coords) {
        updateSettings(UPDATE_SETTINGS, {
          ...settingsState,
          position,
        })

        setPositionCoords(new LatLng(position.coords.latitude, position.coords.longitude))
      }
    })
  }
  useEffect(() => {
    setCurrentPosition()
  }, [])

  // SignalR connection Status
  useEffect(() => {
    const time = setInterval(() => {
      setSignalRConnected(SignalRContext.connection?.state === "Connected")
    }, 2000)

    return () => {
      clearInterval(time)
    }
  }, [])

  const fetchDeviceBySerialNumber = async () => {
    if (serialNumber !== undefined && serialNumber !== "") {
      try {
        const _device = await deviceService.getDeviceBySerialNumber(serialNumber)

        if (_device === undefined) {
          return
        }

        setDeviceId(_device?.deviceId)
        const did = `d-${_device?.deviceId}`

        if (SignalRContext.connection?.state === "Connected") {
          console.log("Connected: ", did)
          SignalRContext.connection?.invoke("ConnectByDevice", did).catch(console.log)
        }

        await userService.updateUserDevice(_device?.deviceId ?? null)

        handleScan()
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleScan = async () => {
    if (serialNumber !== undefined && serialNumber !== "") {
      try {
        await deviceService.scan(serialNumber)
      } catch (error) {
        console.log(error)
      }
    }
  }

  // Subcribing...
  useEffect(() => {
    if (settingsState.deviceId) {
      const did = `d-${settingsState.deviceId}`
      if (SignalRContext.connection?.state === "Connected") {
        SignalRContext.connection?.invoke("ConnectByDevice", did).catch(console.log)
        console.log("Connected: ", did)
      }
    }
  }, [settingsState.deviceId, SignalRContext.connection?.state])

  // Subcribing...
  // useEffect(() => {
  //   if (user && user.userId) {
  //     const uid = `u-${user.userId}`
  //     if (SignalRContext.connection?.state === "Connected") {
  //       SignalRContext.connection?.invoke("ConnectByUser", uid).catch(console.log)
  //       console.log("Connected: ", uid)
  //     }
  //   }
  // }, [SignalRContext.connection?.state])

  // Listening, and handling the payload...
  SignalRContext.useSignalREffect(
    "DeviceUpdate", // Your Event Key
    // @ts-ignore
    (payload: any) => {
      // playDing({id: "ding"});
      addToQueue("ding")
      const newPoint = pointService.processPoint(payload)
      // @ts-ignore
      if (newPoint.lat !== undefined && newPoint.lon !== undefined) {
        // @ts-ignore
        setPointCoords(new LatLng(newPoint.lat, newPoint.lon))
      }
      if (step === "connect device" || step === "detect device") {
        const elapsedTime = (performance.now() - startTimeRef.current) / 1000
        const logPayload = new LogRequest()
        logPayload.Message = `Step: Point Received. Connection session ${connectionGuid}. UserId ${user?.userId}, ${user?.email} has received point for SN ${serialNumber} after ${elapsedTime} seconds.`
        logService.sendLog(logPayload)
      }
      setStep("point received")
    },
    [],
  )

  SignalRContext.useSignalREffect(
    "UserUpdate", // Your Event Key
    // @ts-ignore
    (payload: string) => {
      console.log(payload)
    },
    [],
  )

  const handleAttachToDevice = async () => {
    if (settingsState.deviceId) {
      const did = `d-${settingsState.deviceId}`
      if (SignalRContext.connection?.state === "Connected") {
        SignalRContext.connection?.invoke("ConnectByDevice", did).catch(console.log)
      }
    }

    setCurrentPosition()
    setPointCoords(undefined)
    setStep("connect device")

    handleModalOpen()
    handleClose()
  }

  const handleAttach = async () => {
    await fetchDeviceBySerialNumber()

    await userService.updateUserFeature(-1)

    updateSettings(UPDATE_SETTINGS, {
      ...settingsState,
      activeSurvey: undefined,
      activeFeature: undefined,
    })

    setStep("detect device")
  }

  const [connectionGuid, setConnectionGuid] = useState(v4())
  const startTimeRef = useRef<number>(0)
  const successRef = useRef(false)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleModalOpen = () => {
    const newGuid = v4()
    setConnectionGuid(newGuid)
    const logPayload = new LogRequest()
    logPayload.Message = `Step: Open Modal. Connection session ${newGuid}. UserId ${user?.userId}, ${user?.email} has opened the modal in order to attempt a device connection.`
    logService.sendLog(logPayload)
    startTimeRef.current = performance.now()
    setModalOpen(true)
  }
  const handleModalClose = () => {
    const elapsedTime = (performance.now() - startTimeRef.current) / 1000
    const logPayload = new LogRequest()
    logPayload.Message = `Success: ${successRef.current}. Elapsed Time: ${elapsedTime}. Step: Modal Close. Connection session ${connectionGuid}. UserId ${user?.userId}, ${user?.email} closed connection modal after ${elapsedTime} seconds.`
    logService.sendLog(logPayload)
    setModalOpen(false)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRetake = () => {
    setPointCoords(undefined)
    setStep("detect device")
  }

  const handleConfirm = () => {
    updateSettings(UPDATE_SETTINGS, {
      ...settingsState,
      deviceId: deviceId,
      deviceSerialNumber: serialNumber,
    })
    handleModalClose()
  }

  const handleChange = () => {
    setCurrentPosition()
    setStep("connect device")
    handleModalOpen()
    handleClose()
  }

  const handleDetach = () => {
    if (settingsState.deviceId !== undefined) {
      const did = `d-${settingsState.deviceId}`
      if (SignalRContext.connection?.state === "Connected") {
        SignalRContext.connection?.invoke("DisconnectByDevice", did).catch(console.log)
      }
    }

    updateSettings(UPDATE_SETTINGS, {
      ...settingsState,
      deviceId: undefined,
      deviceSerialNumber: undefined,
    })

    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? "device-popover" : undefined

  const renderSerialNumber = (readOnly: boolean) => (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel htmlFor="serial-number-label">Serial Number</InputLabel>

      <OutlinedInput
        readOnly={readOnly}
        sx={{ bgcolor: theme.palette.background.paper }}
        value={serialNumber}
        id="serial-number-label"
        label="Serial Number"
        onChange={(event) => {
          setSerialNumber(event.target.value)
        }}
      />
      {!readOnly && <FormHelperText>Confirm your Serial Number or enter a new one if changed</FormHelperText>}
    </FormControl>
  )

  const renderPointInformation = () => (
    <Stack gap={1}>
      {renderSerialNumber(true)}

      <FormControl fullWidth variant="outlined" size="small">
        <InputLabel htmlFor="latitude-label">Latitude</InputLabel>
        <OutlinedInput
          readOnly
          sx={{ bgcolor: theme.palette.background.paper }}
          value={pointCoords?.lat}
          id="latitude-label"
          label="Latitude"
        />
      </FormControl>

      <FormControl fullWidth variant="outlined" size="small">
        <InputLabel htmlFor="longitude-label">Longitude</InputLabel>
        <OutlinedInput
          readOnly
          sx={{ bgcolor: theme.palette.background.paper }}
          value={pointCoords?.lng}
          id="longitude-label"
          label="Longitude"
        />
      </FormControl>
    </Stack>
  )

  const renderSteps = () => {
    if (step === "connect device") {
      return renderSerialNumber(false)
    }

    if (step === "detect device") {
      return (
        <Box>
          <Typography sx={{ fontWeight: "bold" }} typography={"h6"}>
            Shoot a point
          </Typography>
          <Typography typography={"caption"} color={"text.secondary"}>
            Use your locating device{" "}
            <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>{settingsState.deviceId}</span> to shoot a
            point at your current location. You do not need to start a survey or select a survey type on the device.
          </Typography>

          <Box my={2}>
            <Typography typography={"caption"} color="secondary" marginBottom={1}>
              Waiting for point...
            </Typography>
            <LinearProgress color="secondary" />
          </Box>
        </Box>
      )
    }

    if (step === "point received") {
      return renderPointInformation()
    }
  }

  const renderBottomSheet = () => (
    <Drawer open={showBottomSheet} onClose={() => setShowBottomSheet(false)} anchor={"bottom"}>
      <FeatureSelectorSheet />
    </Drawer>
  )

  const renderMap = () => (
    <Box
      width={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={300}
      borderRadius={4}
      overflow={"hidden"}>
      {positionCoords ? (
        <MapContainer
          zoom={15}
          center={[positionCoords.lat, positionCoords.lng]}
          ref={mapRef}
          zoomControl={false}
          style={{ width: "100%", height: "100%" }}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {pointCoords && (
            <Marker
              position={[pointCoords.lat, pointCoords.lng]}
              icon={
                new LeafletIcon({
                  iconUrl: deviceSvgUrl,
                  iconSize: [32, 32],
                  iconAnchor: [16, 32],
                  popupAnchor: [0, -32],
                })
              }
              zIndexOffset={200}></Marker>
          )}

          <Marker
            position={[positionCoords.lat, positionCoords.lng]}
            icon={
              new LeafletIcon({
                iconUrl: appSvgUrl,
                iconSize: [32, 32],
                iconAnchor: [16, 32],
                popupAnchor: [0, -32],
              })
            }
            zIndexOffset={100}></Marker>
        </MapContainer>
      ) : (
        <CircularProgress />
      )}
    </Box>
  )

  const renderModal = () => (
    <Dialog open={modalOpen} fullWidth onClose={handleModalClose}>
      <Box bgcolor={theme.palette.background.paper}>
        <Box py={1.5} px={2} justifyContent={"space-between"} alignItems={"center"} display={"flex"}>
          <Typography sx={{ textTransform: "capitalize" }} id="modal-modal-title" variant="body1">
            {step}
          </Typography>
          <IconButton onClick={handleModalClose} size="small">
            <Close fontSize="small" />
          </IconButton>
        </Box>

        <Box p={2}>
          <Stack gap={2}>
            {renderMap()}

            {renderSteps()}
          </Stack>
        </Box>

        <Divider />

        <Box gap={1} display={"flex"} justifyContent={"flex-end"} p={1.5}>
          {step !== "connect device" && (
            <Button
              sx={{ borderRadius: 24, fontWeight: 500, textTransform: "capitalize", px: 2 }}
              onClick={() => {
                const elapsedTime = (performance.now() - startTimeRef.current) / 1000
                const logPayload = new LogRequest()
                logPayload.Message = `Step: Cancel Connection. Connection session ${connectionGuid}. UserId ${user?.userId}, ${user?.email} clicked Cancel after ${elapsedTime} seconds.`
                logService.sendLog(logPayload)
                setStep("connect device")
              }}
              disableElevation>
              Cancel
            </Button>
          )}
          {step === "connect device" && (
            <>
              <Button
                sx={{ borderRadius: 24, fontWeight: 500, textTransform: "capitalize", px: 2 }}
                onClick={() => {
                  const elapsedTime = (performance.now() - startTimeRef.current) / 1000
                  const logPayload = new LogRequest()
                  logPayload.Message = `Step: Cancel Modal. Connection session ${connectionGuid}. UserId ${user?.userId}, ${user?.email} clicked Cancel after ${elapsedTime} seconds.`
                  logService.sendLog(logPayload)
                  handleModalClose()
                }}
                disableElevation>
                Cancel
              </Button>
              <Button
                sx={{ borderRadius: 24, fontWeight: 500, textTransform: "capitalize", px: 2 }}
                onClick={() => {
                  const elapsedTime = (performance.now() - startTimeRef.current) / 1000
                  const logPayload = new LogRequest()
                  logPayload.Message = `Step: Connection Attempt. Connection session ${connectionGuid}. UserId ${user?.userId}, ${user?.email} is initiating a connection attempt for SN ${serialNumber} after ${elapsedTime} seconds.`
                  logService.sendLog(logPayload)
                  handleAttach()
                }}
                disabled={serialNumber === undefined || serialNumber === "" || positionCoords === undefined}
                variant="contained"
                disableElevation>
                Attach to Device
              </Button>
            </>
          )}
          {step === "point received" && (
            <>
              <Button
                onClick={() => {
                  const elapsedTime = (performance.now() - startTimeRef.current) / 1000
                  const logPayload = new LogRequest()
                  logPayload.Message = `Step: Retake. Connection session ${connectionGuid}. UserId ${user?.userId}, ${user?.email} has chosen to retake a point for SN ${serialNumber} after ${elapsedTime} seconds.`
                  logService.sendLog(logPayload)
                  handleRetake()
                }}
                variant="outlined"
                size="small"
                disableElevation>
                Retake
              </Button>
              <Button
                onClick={() => {
                  const elapsedTime = (performance.now() - startTimeRef.current) / 1000
                  const logPayload = new LogRequest()
                  logPayload.Message = `Step: Final/Confirm. Connection session ${connectionGuid}. UserId ${user?.userId}, ${user?.email} has confirmed connection point for SN ${serialNumber} after ${elapsedTime} seconds.`
                  logService.sendLog(logPayload)
                  successRef.current = true
                  handleConfirm()
                }}
                disabled={
                  serialNumber === undefined || serialNumber === "" || settingsState.position?.coords === undefined
                }
                size="small"
                variant="contained"
                disableElevation>
                Confirm
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  )

  const renderDevicePopover = () => (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      slotProps={{
        paper: {
          sx: {
            borderRadius: 4,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: theme.palette.grey[100],
            boxShadow: design.shadow,
          },
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}>
      <Box minWidth={250}>
        <Box bgcolor={theme.palette.background.paper} p={2}>
          <Typography typography={"body2"} color={"text.secondary"} fontWeight={"bold"}>
            Device
          </Typography>
        </Box>
        <Divider />
        <Stack p={2} gap={1}>
          {settingsState.deviceId ? (
            <Stack gap={1}>
              {renderSerialNumber(true)}

              <Button
                onClick={() => {
                  handleScan()
                  handleClose()
                }}
                variant="outlined"
                size="small"
                color="success"
                fullWidth
                sx={{ textTransform: "capitalize" }}>
                Refresh/Scan
              </Button>

              <Button
                onClick={handleChange}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ textTransform: "capitalize" }}>
                Change
              </Button>

              <Button
                onClick={handleDetach}
                variant="outlined"
                size="small"
                color="error"
                fullWidth
                sx={{ textTransform: "capitalize" }}>
                Detach
              </Button>
            </Stack>
          ) : (
            <Stack alignContent={"space-between"}>
              <Stack alignItems={"center"} my={3}>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} px={2}>
                  <HiOutlineSignalSlash size={64} opacity={0.3} />
                </Box>
                <Typography sx={{ opacity: 0.5, fontSize: 14 }}>No Device Connected</Typography>
              </Stack>

              <Button
                onClick={handleAttachToDevice}
                variant="outlined"
                sx={{ textTransform: "capitalize", borderRadius: 24 }}>
                Connect
              </Button>
            </Stack>
          )}
        </Stack>
      </Box>
    </Popover>
  )
  const renderFeatureIcon = (feature: IFeatureStyled, size: number = 20) => {
    if (feature.icon?.toLowerCase().includes("svg")) {
      const coloredSvg = changeSvgColor(feature.icon, feature.iconColor ?? "#111")
      // icon = svgToDataURL(coloredSvg);
      // return <SvgIcon>{coloredSvg}</SvgIcon>
      return (
        <span
          style={{ width: `${size}px`, height: `${size}px`, marginRight: 2 }}
          dangerouslySetInnerHTML={{ __html: coloredSvg }}
        />
      )
    } else if (!feature.icon?.toLowerCase().includes("http")) {
      return (
        <img
          src={`${BASE_API_URL}/map/icon?id=${feature?.mapSymbolId ?? 0}&color=${feature.iconColor?.replace("#", "")}`}
          alt=""
          style={{ width: `${size}px`, height: `${size}px`, marginRight: 2 }}
        />
      )
    }
    return <img src={feature.icon} alt="" style={{ width: "16px", height: "16px", marginRight: 2 }} />
  }

  function overrideHideConnect() {
    return false
  }

  if (!isDesktop) {
    return (
      // <Stack position="sticky" top={0} left={0} zIndex={1}>
      //   <Box
      //     sx={{
      //       backgroundColor: "rgba(255, 255, 255, 0.7)",
      //       backdropFilter: "blur(5px)",
      //     }}>
      <Stack direction={"row"} px={2} pt={2} justifyContent={"space-between"} alignItems={"center"}>
        <Stack direction={"row"} alignItems={"center"}>
          <Button onClick={() => openSidebar(<SelectWorldList />)}>
            <Stack alignItems={"center"} justifyContent={"center"} gap={0.5}>
              <HiGlobeAmericas size={32} />
              {mode === "regular" && (
                <Stack gap={0.3} alignItems="center">
                  {settingsState.activeWorld ? (
                    <Typography sx={{ fontSize: 11, lineHeight: 1, textTransform: "capitalize" }}>
                      {settingsState.activeWorld.worldName}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: 11,
                        lineHeight: 1,
                        opacity: 0.6,
                        color: theme.palette.grey[700],
                        fontStyle: "italic",
                        textTransform: "capitalize",
                      }}>
                      None
                    </Typography>
                  )}
                </Stack>
              )}
            </Stack>
          </Button>
          |
          <Button onClick={() => openSidebar(<SelectProjectList />)}>
            <Stack alignItems={"center"} justifyContent={"center"} gap={0.5}>
              <VscTools size={32} />
              <Stack gap={0.3} alignItems="center">
                {settingsState.activeProject ? (
                  <Typography sx={{ fontSize: 11, lineHeight: 1, textTransform: "capitalize" }}>
                    {settingsState.activeProject?.projectName}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: 11,
                      lineHeight: 1,
                      opacity: 0.6,
                      color: theme.palette.grey[700],
                      fontStyle: "italic",
                      textTransform: "capitalize",
                    }}>
                    None
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Button>
          |
          <Button
            onClick={() => {
              if (settingsState.activeSurvey) {
                navigate(`/project/jobs/${settingsState.activeSurvey?.surveyId}`)
              } else {
                openSidebar(<SelectSurveyList />)
              }
            }}>
            <Stack alignItems={"center"} justifyContent={"center"} gap={0.5}>
              <GoChecklist size={32} />
              <Stack gap={0.3} alignItems="center">
                {settingsState.activeSurvey ? (
                  <Typography sx={{ fontSize: 11, lineHeight: 1, textTransform: "capitalize" }}>
                    {settingsState.activeSurvey?.surveyName}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: 11,
                      lineHeight: 1,
                      opacity: 0.6,
                      color: theme.palette.grey[700],
                      fontStyle: "italic",
                      textTransform: "capitalize",
                    }}>
                    None
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Button>
          |
          <Button disabled>
            <Stack alignItems={"center"} justifyContent={"center"} gap={0.5}>
              {settingsState.activeFeature ? (
                renderFeatureIcon(settingsState.activeFeature, 30)
              ) : (
                <PiMapPinLine size={30} />
              )}
              <Stack gap={0.3} alignItems="center">
                {settingsState.activeFeature ? (
                  <Typography sx={{ fontSize: 11, lineHeight: 1, textTransform: "capitalize" }}>
                    {settingsState.activeFeature?.featureName}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: 11,
                      lineHeight: 1,
                      opacity: 0.6,
                      color: theme.palette.grey[700],
                      fontStyle: "italic",
                      textTransform: "capitalize",
                    }}>
                    None
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Button>
        </Stack>
        <Badge
          badgeContent={
            isOnline ? (
              isSyncing ? (
                <CircularProgress size={12} thickness={6} sx={{ color: "white" }} />
              ) : (
                <HiStatusOnline color="white" />
              )
            ) : (
              <HiStatusOffline color="white" />
            )
          }
          color={isOnline ? "success" : "error"}>
          <Button
            onClick={handleClick}
            color={settingsState.deviceSerialNumber ? (signalRConnected ? "success" : "error") : "secondary"}>
            <Stack gap={0.8} alignItems={"center"}>
              {settingsState.deviceSerialNumber ? <RiGpsFill size={24} /> : <PiGpsSlashDuotone size={24} />}
              <Typography sx={{ fontSize: 12, fontWeight: 500, lineHeight: 1, textTransform: "capitalize" }}>
                {settingsState.deviceSerialNumber ? settingsState.deviceSerialNumber : "No Device"}
              </Typography>
            </Stack>
          </Button>
        </Badge>

        <BlobCountBadge />
        {/* {blobCount > 0 && (
          <Badge badgeContent={blobCount} color="error" overlap="circular" max={9999}>
            <BsDatabaseUp size={24} />
          </Badge>
        )} */}
        {/* </Stack>
          <StorageUsageBar />
        </Box> */}
        {renderDevicePopover()}
        {renderModal()}
      </Stack>
    )
  }

  return (
    <Stack position={"sticky"} top={0} left={0} zIndex={99}>
      <Box
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          backdropFilter: "blur(5px)",
        }}>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={1}
          sx={{
            paddingY: 1.5,
            paddingX: 3,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            backdropFilter: "blur(5px)",
          }}>
          <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
            <Button onClick={() => openSidebar(<SelectWorldList />)}>
              <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                <HiGlobeAmericas size={24} />
                {/* {mode === "regular" && ( */}
                <Stack gap={0.3} alignItems="flex-start">
                  <Typography sx={{ fontSize: 11, lineHeight: 1, opacity: 0.5, textTransform: "capitalize" }}>
                    World
                  </Typography>

                  {settingsState.activeWorld ? (
                    <Typography sx={{ fontSize: 14, lineHeight: 1, textTransform: "capitalize" }}>
                      {settingsState.activeWorld.worldName}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: 12,
                        lineHeight: 1,
                        opacity: 0.6,
                        color: theme.palette.grey[700],
                        fontStyle: "italic",
                        textTransform: "capitalize",
                      }}>
                      None
                    </Typography>
                  )}
                </Stack>
                {/* )} */}
              </Stack>
            </Button>
            {settingsState.activeWorld && (
              <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                {" "}
                |{" "}
                <Button onClick={() => openSidebar(<SelectProjectList />)}>
                  <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                    <VscTools size={24} />
                    {/* {mode === "regular" && ( */}
                    <Stack gap={0.3} alignItems="flex-start">
                      <Typography sx={{ fontSize: 11, lineHeight: 1, opacity: 0.5, textTransform: "capitalize" }}>
                        Project
                      </Typography>
                      {settingsState.activeProject ? (
                        <Typography sx={{ fontSize: 14, lineHeight: 1, textTransform: "capitalize" }}>
                          {settingsState.activeProject?.projectName}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: 12,
                            lineHeight: 1,
                            opacity: 0.6,
                            color: theme.palette.grey[700],
                            fontStyle: "italic",
                            textTransform: "capitalize",
                          }}>
                          None
                        </Typography>
                      )}
                    </Stack>
                    {/* )} */}
                  </Stack>
                </Button>
              </Stack>
            )}
            {settingsState.activeProject && (
              <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                {" "}
                |{" "}
                <Button
                  onClick={() => {
                    if (settingsState.activeSurvey) {
                      navigate(`/project/jobs/${settingsState.activeSurvey?.surveyId}`)
                    } else {
                      openSidebar(<SelectSurveyList />)
                    }
                  }}>
                  <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                    <GoChecklist size={24} />
                    {/* {mode === "regular" && ( */}
                    <Stack gap={0.3} alignItems="flex-start">
                      <Typography sx={{ fontSize: 11, lineHeight: 1, opacity: 0.5, textTransform: "capitalize" }}>
                        Job
                      </Typography>
                      {settingsState.activeSurvey ? (
                        <Typography sx={{ fontSize: 14, lineHeight: 1, textTransform: "capitalize" }}>
                          {settingsState.activeSurvey?.surveyName}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: 12,
                            lineHeight: 1,
                            opacity: 0.6,
                            color: theme.palette.grey[700],
                            fontStyle: "italic",
                            textTransform: "capitalize",
                          }}>
                          None
                        </Typography>
                      )}
                    </Stack>
                    {/* )} */}
                  </Stack>
                </Button>
              </Stack>
            )}
            {settingsState.activeSurvey && (
              <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                {" "}
                |{" "}
                <Button onClick={() => openSidebar(<FeatureSelectorSheet />)}>
                  <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                    {settingsState.activeFeature ? (
                      renderFeatureIcon(settingsState.activeFeature)
                    ) : (
                      <PiMapPinLine size={24} />
                    )}
                    {/* {mode === "regular" && ( */}
                    <Stack gap={0.3} alignItems="flex-start">
                      <Typography sx={{ fontSize: 11, lineHeight: 1, opacity: 0.5, textTransform: "capitalize" }}>
                        Feature
                      </Typography>
                      <Typography sx={{ fontSize: 14, lineHeight: 1, textTransform: "capitalize" }}>
                        {settingsState.activeFeature?.featureName ?? "None"}
                      </Typography>
                    </Stack>
                    {/* )} */}
                  </Stack>
                </Button>
              </Stack>
            )}
          </Stack>

          <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
            {/* {isDesktop && !overrideHideConnect() ? null : ( */}
            {/* <Button
              sx={{ borderRadius: 12, px: 2 }}
              onClick={handleClick}
              disableElevation
              variant="contained"
              color={settingsState.deviceSerialNumber ? (signalRConnected ? "success" : "error") : "secondary"}>
              <Stack gap={0.8} flexDirection={"row"} alignItems={"center"}>
                {mode === "regular" && (
                  <Typography sx={{ fontSize: 14, fontWeight: 500, lineHeight: 1, textTransform: "capitalize" }}>
                    {settingsState.deviceSerialNumber ? settingsState.deviceSerialNumber : "Not Connected"}
                  </Typography>
                )}

                {settingsState.deviceSerialNumber ? <PiWifiHigh size={24} /> : <PiWifiSlash size={24} />}
              </Stack>
            </Button> */}

            <Button
              onClick={handleClick}
              color={settingsState.deviceSerialNumber ? (signalRConnected ? "success" : "error") : "secondary"}>
              <Stack gap={0.8} alignItems={"center"}>
                {settingsState.deviceSerialNumber ? <RiGpsFill size={24} /> : <PiGpsSlashDuotone size={24} />}
                {/* <Typography sx={{ fontSize: 12, fontWeight: 500, lineHeight: 1, textTransform: "capitalize" }}>
                  {settingsState.deviceSerialNumber ? settingsState.deviceSerialNumber : "No Device"}
                </Typography> */}
              </Stack>
            </Button>
            {/* )} */}
            <BlobCountBadge />
            <Profile
              mode={mode}
              firstName={user?.firstName ?? ""}
              lastName={user?.lastName ?? ""}
              email={user?.email ?? ""}
            />
          </Stack>
        </Stack>
      </Box>
      <Divider sx={{ opacity: 0.5 }} />
      {renderDevicePopover()}
      {renderModal()}
      {renderBottomSheet()}
    </Stack>
  )
}

export default StatusBar
