import {
  Avatar,
  Stack,
  Typography,
  Button,
  useMediaQuery,
  type Theme,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Badge,
  keyframes,
  CircularProgress,
} from "@mui/material"
import React from "react"
import { VscAccount } from "react-icons/vsc"
import { design } from "../../../constants/design.constants"
import { HiOutlineLogout, HiStatusOffline, HiStatusOnline } from "react-icons/hi"
import { useMsal } from "@azure/msal-react"
import { Link } from "react-router-dom"
import { useSync } from "../../../hooks/useSync"
import styled from "@mui/styles/styled"

type Props = {
  firstName: string
  lastName: string
  email: string
  image?: string
  mode: "compact" | "regular"
}

function Profile({ firstName, lastName, email, image, mode }: Props) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(menuEl)
  const { instance } = useMsal()
  const { isOnline, isSyncing } = useSync()

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuEl(null)
  }

  const handleLogout = () => {
    instance.logoutRedirect()
  }

  const renderMenu = () => (
    <Menu
      id="basic-menu"
      anchorEl={menuEl}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      open={open}
      style={{
        width: "100%",
      }}
      slotProps={{
        paper: {
          style: {
            width: design.drawerWidth - 2,
            borderRadius: 12,
            boxShadow: design.shadow,
          },
        },
      }}
      onClose={handleMenuClose}>
      <MenuItem component={Link} to="/settings/database" onClick={handleMenuClose}>
        <Stack width={"100%"} direction={"row"} justifyContent={"space-between"} alignContent={"center"} gap={1}>
          <Typography fontSize={14}>Local Database Info</Typography>
        </Stack>
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          handleLogout()
        }}>
        <Stack width={"100%"} direction={"row"} justifyContent={"space-between"} alignContent={"center"} gap={1}>
          <Typography fontSize={14}>Sign Out</Typography>
          <HiOutlineLogout size={18} />
        </Stack>
      </MenuItem>
    </Menu>
  )
  if (isDesktop && mode === "regular") {
    return (
      <Stack gap={1.5} flexDirection={"row"} alignItems={"center"}>
        <Badge
          badgeContent={
            isOnline ? (
              isSyncing ? (
                <CircularProgress size={12} thickness={6} sx={{ color: "white" }} />
              ) : (
                <HiStatusOnline color="white" />
              )
            ) : (
              <HiStatusOffline color="white" />
            )
          }
          color={isOnline ? "success" : "error"}>
          <Button
            onClick={handleMenuOpen}
            fullWidth
            sx={{ textTransform: "capitalize", borderRadius: 3, paddingRight: 2 }}>
            <Stack gap={1.5} direction={"row"} alignItems={"center"}>
              {image ? <Avatar style={{ width: 24, height: 24 }} src={image} /> : <VscAccount size={22} />}
              <Stack alignItems={"flex-start"} justifyContent={"flex-start"}>
                <Typography fontWeight={700} sx={{ opacity: 0.9, lineHeight: 1 }} fontSize={"small"}>
                  {lastName && lastName !== "" ? `${lastName}, ` : ""} {firstName}
                </Typography>
                <Typography sx={{ opacity: 0.7, textTransform: "lowercase" }} fontSize={10}>
                  {email}
                </Typography>
              </Stack>
            </Stack>
          </Button>
        </Badge>

        {renderMenu()}
      </Stack>
    )
  }

  return (
    <>
      <Badge
        badgeContent={
          isOnline ? (
            isSyncing ? (
              <CircularProgress size={12} thickness={6} sx={{ color: "white" }} />
            ) : (
              <HiStatusOnline color="white" />
            )
          ) : (
            <HiStatusOffline color="white" />
          )
        }
        color={isOnline ? "success" : "error"}>
        <IconButton onClick={handleMenuOpen}>
          <VscAccount />
        </IconButton>
      </Badge>
      {renderMenu()}
    </>
  )
}

export default Profile
