import { PING_ENDPOINT } from "../constants/commonStrings.constant"

export async function isNetworkReachable(): Promise<boolean> {
  try {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 5000)

    const response = await fetch(PING_ENDPOINT, {
      method: "HEAD",
      signal: controller.signal,
      cache: "no-cache",
      headers: { "Cache-Control": "no-cache" },
    })

    clearTimeout(timeoutId)
    return response.ok
  } catch {
    return false
  }
}
