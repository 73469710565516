import { synchronize } from "@nozbe/watermelondb/sync"
import { database } from "./database"
import { BASE_API_URL } from "../constants/commonStrings.constant"
import { apiService } from "../services/api.service"
import SyncLogger from "@nozbe/watermelondb/sync/SyncLogger"
import type { IProject } from "../interfaces/project.interface"
import { isNetworkReachable } from "../utils/network.util"

// Keep sync logs for debugging
const syncLogger = new SyncLogger(20)

interface SyncOptions {
  project?: IProject
  reset?: boolean
  retryCount?: number
}

const MAX_RETRY_ATTEMPTS = 1
const RETRY_DELAY_MS = 1000

async function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export async function sync({ project, reset, retryCount = 0 }: SyncOptions = {}): Promise<void> {
  const log = syncLogger.newLog()

  try {
    // Check network before starting sync
    const isOnline = await isNetworkReachable()
    if (!isOnline) {
      throw new Error("Network unavailable")
    }

    if (reset) {
      await database.write(async () => {
        await database.unsafeResetDatabase()
        console.log("✅ DB reset")
      })
    }
    await synchronize({
      database,
      log,
      pullChanges: async ({ lastPulledAt, schemaVersion, migration }) => {
        console.log("🍉 ⬇️ Pulling changes ...", { lastPulledAt })
        const urlParams = `lastSyncAt=${lastPulledAt ?? 0}&schema_version=${schemaVersion ?? 0}&migration=${encodeURIComponent(
          JSON.stringify(migration),
        )}&projectId=${project?.projectId}`
        const response = await apiService.get(`${BASE_API_URL}/sync?${urlParams}`)
        if (!(response.status === 200)) {
          throw new Error("🍉".concat(await response.data))
        }

        const { changes, timestamp } = response.data
        // console.log("Pull response",{changes, timestamp})
        console.log(`🍉 Changes pulled at ${new Date(timestamp).toISOString()} UTC`, changes)
        if (lastPulledAt === null) {
          return { changes, timestamp, experimentalStrategy: "replacement" }
        } else {
          return { changes, timestamp }
        }
      },
      pushChanges: async ({ changes, lastPulledAt }) => {
        console.log("🍉 Pushing changes ...", { changes, lastPulledAt })
        // try {
        const response = await apiService.post(
          `${BASE_API_URL}/sync`,
          { changes },
          {
            headers: { "Content-Type": "application/json" },
          },
        )
        console.log(response)

        if (response.status !== 200) {
          throw new Error(`Push sync failed: ${response.data}`)
        }
        // } catch (error: any) {
        //   if (error.response) {
        //     // The request was made and the server responded with a status code
        //     // that falls out of the range of 2xx
        //     throw new Error(error.response.data || "Unknown Error")
        //   } else if (error.request) {
        //     // The request was made but no response was received
        //     console.error("No response received:", error.request)
        //   } else {
        //     // Something happened in setting up the request that triggered an Error
        //     console.error("Error", error.message)
        //   }
        // }
      },
      migrationsEnabledAtVersion: 2,
    })
  } catch (error) {
    console.error("🍉 Sync failed:", error)

    // Retry logic for recoverable errors
    if (retryCount < MAX_RETRY_ATTEMPTS) {
      console.log(`🔄 Retrying sync (attempt ${retryCount + 1}/${MAX_RETRY_ATTEMPTS})`)
      await delay(RETRY_DELAY_MS)
      return sync({ reset, retryCount: retryCount + 1, project })
    }

    throw error
  }
}
