import { createTable, schemaMigrations, addColumns } from "@nozbe/watermelondb/Schema/migrations"

export default schemaMigrations({
  migrations: [
    {
      toVersion: 3,
      steps: [
        addColumns({
          table: "lineStyles",
          columns: [{ name: "lineLabel", type: "string", isOptional: true }],
        }),
        // Add new tables
        createTable({
          name: "surveys",
          columns: [
            { name: "surveyId", type: "number", isIndexed: true },
            { name: "surveyName", type: "string" },
            { name: "surveyDescription", type: "string" },
            { name: "projectId", type: "number" },
            { name: "projectKey", type: "string" },
            { name: "status", type: "string" },
            { name: "formId", type: "number" },
            { name: "formKey", type: "string", isIndexed: true },
            { name: "formData", type: "string", isOptional: true },
            { name: "formTitle", type: "string" },
            { name: "workTypeName", type: "string" },
            { name: "workTypeKey", type: "string" },
            { name: "workTypeId", type: "number" },
            { name: "createdBy", type: "string" },
            { name: "createdOffset", type: "number", isOptional: true },
            { name: "updatedBy", type: "string", isOptional: true },
            { name: "updatedOffset", type: "number", isOptional: true },
            { name: "deletedAt", type: "number", isOptional: true },
            { name: "deletedBy", type: "string", isOptional: true },
            { name: "deletedOffset", type: "number", isOptional: true },
            { name: "surveyFormStatus", type: "string" },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
        createTable({
          name: "features",
          columns: [
            { name: "featureId", type: "number", isIndexed: true },
            { name: "featureName", type: "string" },
            { name: "featureDescription", type: "string" },
            { name: "surveyId", type: "number" },
            { name: "surveyKey", type: "string" },
            { name: "deviceId", type: "number" },
            { name: "deviceKey", type: "string" },
            { name: "formId", type: "number" },
            { name: "formKey", type: "string" },
            { name: "formTitle", type: "string" },
            { name: "formData", type: "string" },
            { name: "featureTypes", type: "string" },
            { name: "status", type: "string" },
            { name: "workTypeName", type: "string", isOptional: true },
            { name: "workTypeKey", type: "string", isOptional: true },
            { name: "workTypeId", type: "number", isOptional: true },
            { name: "lineStyleId", type: "number", isOptional: true },
            { name: "lineStyleKey", type: "string", isIndexed: true },
            { name: "mapSymbolKey", type: "string", isIndexed: true },
            { name: "mapSymbolId", type: "number", isOptional: true },
            { name: "geoJson", type: "string", isOptional: true },
            { name: "createdBy", type: "string" },
            { name: "createdOffset", type: "number", isOptional: true },
            { name: "updatedBy", type: "string", isOptional: true },
            { name: "updatedOffset", type: "number", isOptional: true },
            { name: "deletedAt", type: "number", isOptional: true },
            { name: "deletedBy", type: "string", isOptional: true },
            { name: "deletedOffset", type: "number", isOptional: true },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
        createTable({
          name: "forms",
          columns: [
            { name: "formId", type: "number", isIndexed: true },
            { name: "formTitle", type: "string", isOptional: true },
            { name: "formDescription", type: "string" },
            { name: "category", type: "string" },
            { name: "typeId", type: "number" },
            { name: "projectId", type: "number" },
            { name: "userId", type: "number" },
            { name: "workTypeId", type: "number" },
            { name: "workTypeKey", type: "string", isIndexed: true },
            { name: "surveyCount", type: "number" },
            { name: "mapSymbolId", type: "number" },
            { name: "lineStyleId", type: "number" },
            { name: "mapSymbolKey", type: "string" },
            { name: "lineStyleKey", type: "string" },
            { name: "color", type: "string" },
            { name: "mapSymbolColor", type: "string" },
            { name: "featureTypes", type: "string" },
            { name: "locateDeviceRequired", type: "boolean" },
            { name: "template", type: "string" },
            { name: "createdBy", type: "string" },
            { name: "createdOffset", type: "number", isOptional: true },
            { name: "objectId", type: "number", isOptional: true },
            { name: "updatedBy", type: "string", isOptional: true },
            { name: "updatedOffset", type: "number", isOptional: true },
            { name: "objectName", type: "string", isOptional: true },
            { name: "deletedAt", type: "number", isOptional: true },
            { name: "deletedBy", type: "string", isOptional: true },
            { name: "deletedOffset", type: "number", isOptional: true },
            { name: "objectKey", type: "string", isOptional: true },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
        createTable({
          name: "featureTypes",
          columns: [
            { name: "featureTypeId", type: "number", isIndexed: true },
            { name: "featureTypeName", type: "string" },
            { name: "sortOrder", type: "number", isOptional: true },
            { name: "createdBy", type: "string", isOptional: true },
            { name: "createdOffset", type: "number", isOptional: true },
            { name: "updatedBy", type: "string", isOptional: true },
            { name: "updatedOffset", type: "number", isOptional: true },
            { name: "deletedAt", type: "number", isOptional: true },
            { name: "deletedBy", type: "string", isOptional: true },
            { name: "deletedOffset", type: "number", isOptional: true },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
        createTable({
          name: "featureEvents",
          columns: [
            { name: "eventId", type: "number" },
            { name: "eventType", type: "string" },
            { name: "featureId", type: "number" },
            { name: "featureKey", type: "string" },
            { name: "pointId", type: "number" },
            { name: "pointKey", type: "string" },
            { name: "createdBy", type: "string", isOptional: true },
            { name: "createdOffset", type: "number", isOptional: true },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
        createTable({
          name: "projects",
          columns: [
            { name: "projectId", type: "number", isIndexed: true },
            { name: "projectNumber", type: "string" },
            { name: "projectName", type: "string" },
            { name: "worldId", type: "number" },
            { name: "worldKey", type: "string" },
            { name: "projectType", type: "string" },
            { name: "createdBy", type: "string", isOptional: true },
            { name: "createdOffset", type: "number", isOptional: true },
            { name: "updatedBy", type: "string", isOptional: true },
            { name: "updatedOffset", type: "number", isOptional: true },
            { name: "deletedAt", type: "number", isOptional: true },
            { name: "deletedBy", type: "string", isOptional: true },
            { name: "deletedOffset", type: "number", isOptional: true },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
        createTable({
          name: "worlds",
          columns: [
            { name: "worldId", type: "number", isIndexed: true },
            { name: "worldName", type: "string" },
            { name: "clientId", type: "number" },
            { name: "createdBy", type: "string", isOptional: true },
            { name: "createdOffset", type: "number", isOptional: true },
            { name: "updatedBy", type: "string", isOptional: true },
            { name: "updatedOffset", type: "number", isOptional: true },
            { name: "deletedAt", type: "number", isOptional: true },
            { name: "deletedBy", type: "string", isOptional: true },
            { name: "deletedOffset", type: "number", isOptional: true },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
        createTable({
          name: "users",
          columns: [
            { name: "userId", type: "number", isIndexed: true },
            { name: "userName", type: "string", isOptional: true },
            { name: "firstName", type: "string", isOptional: true },
            { name: "lastName", type: "number", isOptional: true },
            { name: "email", type: "string", isOptional: true },
            { name: "permissionsData", type: "string", isOptional: true },
            { name: "externalId", type: "string", isOptional: true },
            { name: "roleName", type: "string", isOptional: true },
            { name: "roleId", type: "number", isOptional: true },
            { name: "featureId", type: "number", isOptional: true },
            { name: "featureKey", type: "string", isOptional: true },
            { name: "deviceId", type: "number", isOptional: true },
            { name: "deviceKey", type: "string", isOptional: true },
            { name: "surveyId", type: "number", isOptional: true },
            { name: "surveyKey", type: "string", isOptional: true },
            { name: "createdBy", type: "string", isOptional: true },
            { name: "createdOffset", type: "number", isOptional: true },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
        createTable({
          name: "points",
          columns: [
            { name: "pointId", type: "number", isIndexed: true },
            { name: "featureId", type: "number", isOptional: true },
            { name: "featureKey", type: "string" },
            { name: "geoJson", type: "string" },
            { name: "geomAsText", type: "string" },
            { name: "deviceId", type: "number" },
            { name: "deviceKey", type: "string" },
            { name: "projectId", type: "number", isOptional: true },
            { name: "externalSurveyId", type: "string", isOptional: true },
            { name: "fid", type: "string", isOptional: true },
            { name: "rawData", type: "string", isOptional: true },
            { name: "updatedBy", type: "string", isOptional: true },
            { name: "updatedOffset", type: "number", isOptional: true },
            { name: "deletedAt", type: "number", isOptional: true },
            { name: "deletedBy", type: "string", isOptional: true },
            { name: "deletedOffset", type: "number", isOptional: true },
            { name: "latitude", type: "number", isOptional: true },
            { name: "longitude", type: "number", isOptional: true },
            { name: "createdBy", type: "string", isOptional: true },
            { name: "createdOffset", type: "number", isOptional: true },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
        createTable({
          name: "devices",
          columns: [
            { name: "deviceId", type: "number", isIndexed: true },
            { name: "deviceName", type: "string", isOptional: true },
            { name: "deviceDescription", type: "string", isOptional: true },
            { name: "deviceSerialNumber", type: "string" },
            { name: "createdBy", type: "string", isOptional: true },
            { name: "createdOffset", type: "number", isOptional: true },
            { name: "updatedBy", type: "string", isOptional: true },
            { name: "updatedOffset", type: "number", isOptional: true },
            { name: "deletedAt", type: "number", isOptional: true },
            { name: "deletedBy", type: "string", isOptional: true },
            { name: "deletedOffset", type: "number", isOptional: true },
            { name: "projectId", type: "number", isOptional: true },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
        createTable({
          name: "categories",
          columns: [
            { name: "categoryId", type: "number", isIndexed: true },
            { name: "categoryName", type: "string", isOptional: true },
            { name: "categoryDescription", type: "string", isOptional: true },
            { name: "sortOrder", type: "number", isOptional: true },
            { name: "createdBy", type: "string", isOptional: true },
            { name: "createdOffset", type: "number", isOptional: true },
            { name: "updatedBy", type: "string", isOptional: true },
            { name: "updatedOffset", type: "number", isOptional: true },
            { name: "deletedAt", type: "number", isOptional: true },
            { name: "deletedBy", type: "string", isOptional: true },
            { name: "deletedOffset", type: "number", isOptional: true },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
        createTable({
          name: "auditLogs",
          columns: [
            { name: "auditLogId", type: "number", isIndexed: true },
            { name: "auditType", type: "string", isOptional: true },
            { name: "objectName", type: "string", isOptional: true },
            { name: "objectId", type: "number", isOptional: true },
            { name: "tableName", type: "string", isOptional: true },
            { name: "tableKeyId", type: "number", isOptional: true },
            { name: "data", type: "string", isOptional: true },
            { name: "createdBy", type: "string", isOptional: true },
            { name: "createdOffset", type: "number", isOptional: true },
            { name: "created_at", type: "number" },
            { name: "updated_at", type: "number" },
          ],
        }),
      ],
    },
  ],
})
