import { Query, Model } from "@nozbe/watermelondb"
import { field, readonly, date, children } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"
import type Form from "./Form"
import type { Associations } from "@nozbe/watermelondb/Model"

export default class FeatureType extends Model {
  static table = TableName.FeatureType
  static associations: Associations = {
    [TableName.Form]: { type: "has_many", foreignKey: "featureTypeName" },
  }

  @field("featureTypeId") featureTypeId!: number
  @field("featureTypeName") featureTypeName!: string
  @field("sortOrder") sortOrder?: number
  @readonly @date("created_at") createdAt!: number
  @field("createdBy") createdBy?: string
  @field("createdOffset") createdOffset?: number
  @readonly @date("updated_at") updatedAt?: number
  @field("updatedBy") updatedBy?: string
  @field("updatedOffset") updatedOffset?: number
  @readonly @date("deletedAt") deletedAt?: number
  @field("deletedBy") deletedBy?: string
  @field("deletedOffset") deletedOffset?: number

  @children("forms") forms!: Query<Form>
}
