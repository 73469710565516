import React, { Suspense } from "react"
import { ErrorBoundary } from "@sentry/react"
import { CircularProgress } from "@mui/material"

interface OfflineRouteWrapperProps {
  children: React.ReactNode
}

const OfflineFallback = () => (
  <div className="flex flex-col items-center justify-center min-h-[400px]">
    <h2>Unable to load this page offline</h2>
    <p>This content hasn't been cached for offline use yet.</p>
  </div>
)

const LoadingFallback = () => (
  <div className="flex items-center justify-center min-h-[400px]">
    <CircularProgress />
  </div>
)

export const OfflineRouteWrapper: React.FC<OfflineRouteWrapperProps> = ({ children }) => {
  return (
    <ErrorBoundary fallback={<OfflineFallback />}>
      <Suspense fallback={<LoadingFallback />}>{children}</Suspense>
    </ErrorBoundary>
  )
}
