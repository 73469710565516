import type { OverridableStringUnion } from "@mui/types"
import type { BadgePropsColorOverrides } from "@mui/material"
import { green, grey, red } from "@mui/material/colors"
import type { FieldDataOptions, ISurvey, ISurveyStyled } from "../interfaces"
import type { ISurveyFilterStatus, ISurveyStatus } from "../interfaces/survey.interface"
import { Q } from "@nozbe/watermelondb"
import { database } from "../model/database"
import type Survey from "../model/Survey"
import { TableName } from "../model/schema"
import dayjs from "dayjs"
import {
  WifiTethering,
  AccessTime,
  PlayCircle,
  PauseCircle,
  Archive,
  ListAltOutlined,
  BlockOutlined,
} from "@mui/icons-material"

import { updateWatermelonDBObject } from "../utils/data.util"

class SurveyService {
  async getSurveys(projectId: number): Promise<ISurvey[]> {
    const surveys = await database.collections
      .get<Survey>(TableName.Survey)
      .query(Q.where("deletedAt", Q.eq(null)), Q.where("projectId", projectId))
      .fetch()
    return surveys.map((survey) => this.mapSurvey(survey))
  }

  async search(
    time: string,
    projectId: number,
    searchText?: string,
    formId?: number,
    status?: ISurveyFilterStatus,
  ): Promise<ISurvey[]> {
    let query = database.collections
      .get<Survey>(TableName.Survey)
      .query(Q.where("projectId", projectId), Q.where("deletedAt", Q.eq(null)))

    if (time && time !== "all" && time !== "All") {
      const offset = dayjs().utcOffset() * 60

      if (time === "today") {
        const startOfDay = dayjs().startOf("day").valueOf() - offset
        const endOfDay = dayjs().endOf("day").valueOf() - offset
        query = query.extend(Q.where("created_at", Q.between(startOfDay, endOfDay)))
      } else if (time === "yesterday") {
        const startOfDay = dayjs().subtract(1, "day").startOf("day").valueOf() - offset
        const endOfDay = dayjs().subtract(1, "day").endOf("day").valueOf() - offset
        query = query.extend(Q.where("created_at", Q.between(startOfDay, endOfDay)))
      } else if (time === "last-week") {
        const startOfLastWeek = dayjs().subtract(1, "week").startOf("week").valueOf() - offset
        const endOfLastWeek = dayjs().subtract(1, "week").endOf("week").valueOf() - offset
        query = query.extend(Q.where("created_at", Q.between(startOfLastWeek, endOfLastWeek)))
      } else {
        const dates = time.split(",")
        if (dates.length === 2) {
          const startDate = dayjs(dates[0]).startOf("day").valueOf() - offset
          const endDate = dayjs(dates[1]).endOf("day").valueOf() - offset
          query = query.extend(Q.where("created_at", Q.between(startDate, endDate)))
        } else if (dates.length === 1) {
          const startDate = dayjs(dates[0]).startOf("day").valueOf() - offset
          const endDate = dayjs(dates[0]).endOf("day").valueOf() - offset
          query = query.extend(Q.where("created_at", Q.between(startDate, endDate)))
        }
      }
    }

    if (searchText) {
      query = query.extend(Q.where("surveyName", Q.like(`%${Q.sanitizeLikeString(searchText)}%`)))
    }

    if (formId !== undefined && formId !== null && formId !== -1) {
      query = query.extend(Q.where("formId", formId))
    }
    if (status && status !== "all" && status) {
      if (status === "in progress") {
        query = query.extend(Q.where("status", Q.oneOf(["waiting", "started", "paused"])))
      } else {
        query = query.extend(Q.where("status", status))
      }
    }

    const surveys = await query.fetch()
    return surveys.map((survey) => this.mapSurvey(survey))
  }

  async getSurveysStyled(projectId: number, id?: string): Promise<ISurveyStyled[]> {
    let query = database.collections
      .get<Survey>(TableName.Survey)
      .query(Q.where("deletedAt", Q.eq(null)), Q.where("projectId", projectId))

    if (id) {
      query = query.extend(Q.where("id", id))
    }

    const surveys = await query.fetch()
    return surveys.map((survey) => this.mapSurveyStyled(survey))
  }

  async deleteImage(path: string): Promise<string> {
    await database.write(async () => {
      const attachments = await database.collections.get(TableName.Attachment).query(Q.where("path", path)).fetch()
      if (attachments.length === 0) {
        throw new Error("Image not found")
      }
      attachments.forEach(async (attachment) => {
        await attachment.markAsDeleted()
      })
    })

    return "deleted"
  }

  async getSurvey(id: string): Promise<ISurvey> {
    const survey = await database.collections.get<Survey>(TableName.Survey).find(id)

    return this.mapSurvey(survey)
  }

  async addSurvey(payload: ISurvey): Promise<ISurvey> {
    await database.write(async () => {
      await database.collections.get<Survey>(TableName.Survey).create((survey) => {
        updateWatermelonDBObject(survey, payload)
      })
    })
    return payload
  }

  async updateSurvey(payload: ISurvey): Promise<ISurvey> {
    await database.write(async () => {
      const survey = await database.collections.get(TableName.Survey).find(payload.id ?? "")
      await survey.update((survey) => {
        // assignExcept(survey, payload, "createdAt", "updatedAt", "features", "id");
        updateWatermelonDBObject(survey, payload)
      })
    })
    return payload
  }

  async updateSurveyStatus(id?: string, status?: string): Promise<any> {
    const updatedSurvey = await database.write(async () => {
      const surveyToUpdate = await database.collections.get<Survey>(TableName.Survey).find(id ?? "")
      return surveyToUpdate.update((record) => {
        record.status = status
      })
    })
    return updatedSurvey
  }

  async deleteSurvey(id: string): Promise<string> {
    const survey = await database.collections.get<Survey>(TableName.Survey).find(id)
    await survey.markAsDeleted()
    return "deleted"
  }

  async getOptions(projectId: number): Promise<FieldDataOptions> {
    let query = database.collections.get<Survey>(TableName.Survey).query(Q.where("deletedAt", Q.eq(null)))

    if (projectId) {
      query = query.extend(Q.where("projectId", projectId))
    }

    const surveys = await query.fetch()

    return surveys.reduce((acc, survey) => {
      // Assuming survey has id, name, and description fields
      acc[survey.id] = {
        value: survey.id.toString(),
        name: survey.surveyName ?? "",
        description: survey.surveyDescription,
        pkey: survey.surveyId,
      }
      return acc
    }, {} as FieldDataOptions)
  }

  getStatusColor(status: string | undefined, active: boolean | undefined): string {
    if (active) {
      return "#4fc3f7"
    }
    switch (status) {
      case "waiting":
        return "#aaa"
      case "started":
        return "#81c784"
      case "paused":
        return "#ffb74d"
      case "abandoned":
        return "#e57373"
      case "completed":
        return "#888"
      default:
        return "grey"
    }
  }

  getStatusThemeColor(
    status: string | undefined,
    active: boolean | undefined,
  ): OverridableStringUnion<
    "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning",
    BadgePropsColorOverrides
  > {
    if (active) {
      return "info"
    }
    switch (status) {
      case "waiting":
        return "default"
      case "started":
        return "success"
      case "paused":
        return "warning"
      case "abandoned":
        return "error"
      case "completed":
        return "secondary"
      default:
        return "default"
    }
  }

  getStatusThemeColorAlt(status: string | undefined): any {
    switch (status) {
      case "waiting":
        return {
          color: grey[800],
          backgroundColor: grey[100],
          borderColor: grey[500],
        }
      case "paused":
      case "started":
        return {
          color: green[800],
          backgroundColor: green[50],
          borderColor: green[500],
        }
      case "abandoned":
        return {
          color: red[800],
          backgroundColor: red[50],
          borderColor: red[500],
        }
      case "completed":
        return {
          color: grey[100],
          backgroundColor: grey[700],
          borderColor: grey[500],
        }
      default:
        return {
          color: grey[600],
          backgroundColor: grey[100],
          borderColor: grey[800],
        }
    }
  }

  getStatusIconName(status: string | undefined, active: boolean | undefined) {
    if (active) {
      return "WifiTethering"
    }
    switch (status) {
      case "waiting":
        return "AccessTime"
      case "started":
        return "PlayCircle"
      case "paused":
        return "PauseCircle"
      case "completed":
        return "Archive"
      default:
        return "ListAltOutlined"
    }
  }

  getStatusIcon(status: string | undefined, active: boolean | undefined) {
    if (active) {
      return WifiTethering
    }
    switch (status) {
      case "waiting":
        return AccessTime
      case "started":
        return PlayCircle
      case "paused":
        return PauseCircle
      case "completed":
        return Archive
      default:
        return ListAltOutlined
    }
  }

  private mapSurvey(survey: Survey): ISurvey {
    return {
      surveyId: survey.surveyId,
      surveyName: survey.surveyName,
      surveyDescription: survey.surveyDescription,
      projectId: survey.projectId,
      status: survey.status as ISurveyStatus,
      surveyFormStatus: survey.surveyFormStatus as ISurveyStatus,
      workTypeId: survey.workTypeId,
      workTypeName: survey.workTypeName,
      formTitle: survey.formTitle,
      formId: survey.formId,
      formData: survey.formData,
      createdAt: survey.createdAt,
      createdBy: survey.createdBy,
      createdOffset: survey.createdOffset,
      updatedAt: survey.updatedAt,
      updatedBy: survey.updatedBy,
      updatedOffset: survey.updatedOffset,
      deletedAt: survey.deletedAt,
      deletedBy: survey.deletedBy,
      deletedOffset: survey.deletedOffset,
      id: survey.id,
    }
  }

  private mapSurveyStyled(survey: Survey): ISurveyStyled {
    return {
      surveyId: survey.surveyId,
      surveyName: survey.surveyName!,
      surveyDescription: survey.surveyDescription,
      projectId: survey.projectId,
      color: this.getStatusColor(survey.status, false),
      iconColor: this.getStatusColor(survey.status, false),
      icon: this.getStatusIconName(survey.status, false),
      weight: "normal",
      opacity: "1",
      lineCap: "round",
      lineJoin: "round",
      lineLabel: "",
      dashArray: "none",
      dashOffset: "0",
      thumbnail: "",
      shadowIcon: "",
      shadowSize: "",
      iconAnchor: "",
      shadowAnchor: "",
      popupAnchor: "",
    }
  }
}

export const surveyService = new SurveyService()
