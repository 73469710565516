import { Model } from "@nozbe/watermelondb"
import { field, readonly, date } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"

export default class World extends Model {
  static table = TableName.World

  @field("worldId") worldId!: number
  @field("worldName") worldName?: string
  @field("clientId") clientId?: number
  @readonly @date("created_at") createdAt!: number
  @field("createdBy") createdBy?: string
  @field("createdOffset") createdOffset?: number
  @readonly @date("updated_at") updatedAt?: number
  @field("updatedBy") updatedBy?: string
  @field("updatedOffset") updatedOffset?: number
  @readonly @date("deletedAt") deletedAt?: number
  @field("deletedBy") deletedBy?: string
  @field("deletedOffset") deletedOffset?: number
}
