import { Model } from "@nozbe/watermelondb"
import { TableName } from "./schema"
import { date, field, readonly, writer } from "@nozbe/watermelondb/decorators"

export default class User extends Model {
  static table = TableName.User

  @field("userId") userId!: number
  @field("userName") userName?: string
  @field("firstName") firstName?: string
  @field("lastName") lastName?: string
  @field("email") email?: string
  @field("externalId") externalId?: string
  @field("roleId") roleId?: number
  @field("roleName") roleName?: string
  @field("permissionsData") permissionsData?: string
  @field("deviceId") deviceId?: number
  @field("deviceKey") deviceKey?: string
  @field("featureId") featureId?: number
  @field("featureKey") featureKey?: string
  @field("projectId") projectId?: number
  @field("surveyId") surveyId?: number
  @field("surveyKey") surveyKey?: string
  @readonly @date("created_at") createdAt!: number
  @field("createdBy") createdBy?: string
  @field("createdOffset") createdOffset?: number
  @readonly @date("updated_at") updatedAt?: number
  @field("updatedBy") updatedBy?: string
  @field("updatedOffset") updatedOffset?: number
  @readonly @date("deletedAt") deletedAt?: number
  @field("deletedBy") deletedBy?: string
  @field("deletedOffset") deletedOffset?: number

  @writer async setFeatureId(featureId: number | undefined) {
    await this.update((user) => {
      user.featureId = featureId
    })
  }
  @writer async setFeature(featureKey: string | undefined) {
    await this.update((user) => {
      user.featureKey = featureKey
    })
  }
  @writer async setDevice(deviceKey: string | undefined) {
    await this.update((user) => {
      user.deviceKey = deviceKey
    })
  }
  @writer async setDeviceId(deviceId: number | undefined) {
    await this.update((user) => {
      user.deviceId = deviceId
    })
  }
}
