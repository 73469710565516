import { Model, type Query, type Relation } from "@nozbe/watermelondb"
import { field, relation, children } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"
import type { Associations } from "@nozbe/watermelondb/Model"
import type Survey from "./Survey"
import type WorkType from "./WorkType"
import type LineStyle from "./LineStyle"
import type MapSymbol from "./MapSymbol"
import type Feature from "./Feature"

export default class Form extends Model {
  static table = TableName.Form
  static associations: Associations = {
    [TableName.Survey]: { type: "has_many", foreignKey: "formKey" },
    [TableName.Feature]: { type: "has_many", foreignKey: "formKey" },
    [TableName.WorkType]: { type: "belongs_to", key: "workTypeKey" },
    [TableName.LineStyle]: { type: "belongs_to", key: "lineStyleKey" },
    [TableName.MapSymbol]: { type: "belongs_to", key: "mapSymbolKey" },
  }

  @field("formId") formId!: number
  @field("formTitle") formTitle?: string
  @field("formDescription") formDescription?: string
  @field("category") category?: string
  @field("typeId") typeId?: number
  @field("projectId") projectId?: number
  @field("userId") userId?: number
  @field("workTypeId") workTypeId?: number
  @field("workTypeKey") workTypeKey?: string
  @field("surveyCount") surveyCount?: number
  @field("mapSymbolId") mapSymbolId?: number
  @field("lineStyleId") lineStyleId?: number
  @field("mapSymbolKey") mapSymbolKey?: string
  @field("lineStyleKey") lineStyleKey?: string
  @field("color") color?: string
  @field("mapSymbolColor") mapSymbolColor?: string
  @field("featureTypes") featureTypes?: string
  @field("locateDeviceRequired") locateDeviceRequired?: boolean
  @field("template") template?: string
  @field("created_at") createdAt!: number
  @field("createdBy") createdBy?: string
  @field("createdOffset") createdOffset?: number
  @field("updated_at") updatedAt?: number
  @field("updatedBy") updatedBy?: string
  @field("updatedOffset") updatedOffset?: number
  @field("deletedAt") deletedAt?: number
  @field("deletedBy") deletedBy?: string
  @field("deletedOffset") deletedOffset?: number

  @children("surveys") surveys?: Query<Survey>
  @children("features") features?: Query<Feature>
  @relation("workTypes", "workTypeKey") workType?: Relation<WorkType>
  @relation("lineStyle", "lineStyleKey") lineStyle?: Relation<LineStyle>
  @relation("mapSymbol", "mapSymbolKey") mapSymbol?: Relation<MapSymbol>
}
