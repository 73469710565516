import { Model } from "@nozbe/watermelondb"
import { field, readonly, date } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"

export default class Category extends Model {
  static table = TableName.Category

  @field("categoryId") categoryId!: number
  @field("categoryName") categoryName?: string
  @field("categoryDescription") categoryDescription?: string
  @field("sortOrder") sortOrder?: number
  @field("createdBy") createdBy?: string
  @field("createdOffset") createdOffset?: number
  @field("updatedBy") updatedBy?: string
  @field("updatedOffset") updatedOffset?: number
  @readonly @date("deletedAt") deletedAt?: Date
  @field("deletedBy") deletedBy?: string
  @field("deletedOffset") deletedOffset?: number
  @readonly @date("created_at") createdAt!: number
  @readonly @date("updated_at") updatedAt?: number
}
