import { Model } from "@nozbe/watermelondb"
import { field, readonly, date } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"

export default class AuditLog extends Model {
  static table = TableName.AuditLog

  @field("auditLogId") auditLogId!: number
  @field("auditType") auditType?: string
  @field("objectName") objectName?: string
  @field("objectId") objectId?: number
  @field("tableName") tableName?: string
  @field("tableKeyId") tableKeyId?: number
  @field("data") data?: string
  @field("createdBy") createdBy?: string
  @field("createdOffset") createdOffset?: number
  @readonly @date("created_at") createdAt!: number
  @readonly @date("updated_at") updatedAt?: number
}
