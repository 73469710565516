import React, { useState, useEffect } from "react"
import MapLayer from "./MapLayer"
import type { GeoJsonObject } from "geojson"
import { featureService } from "../../../services"

interface AsyncMapLayerProps {
  featureKey: string
  featureName: string
  isDragging: boolean
  zoomMap: boolean
  pointsCollection: any
  onFeatureSelect: (feature: any) => void
  setZoomMap: (value: boolean) => void
}

const AsyncMapLayer: React.FC<AsyncMapLayerProps> = ({
  featureKey,
  featureName,
  isDragging,
  zoomMap,
  pointsCollection,
  onFeatureSelect,
  setZoomMap,
}) => {
  const [geoJsonData, setGeoJsonData] = useState<GeoJsonObject | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await featureService.generateGeometries(featureKey)
        setGeoJsonData(data)
      } catch (error) {
        console.error("Error fetching GeoJSON data:", error)
        // Handle the error appropriately
      }
    }

    fetchData()
  }, [featureKey, pointsCollection])

  if (!geoJsonData) {
    return null // Or a loading indicator
  }

  return (
    <MapLayer
      key={featureKey}
      layerId={featureKey}
      layerName={featureName}
      data={geoJsonData}
      onSelect={() => {}}
      isDragging={isDragging}
      onEdit={() => {}}
      hiddenPointId={null}
      zoomMap={zoomMap}
      onFeatureSelect={onFeatureSelect}
      setZoomMap={setZoomMap}
    />
  )
}

export default AsyncMapLayer
