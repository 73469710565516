import type { ReactNode } from "react"
import { createContext, useContext, useEffect, useRef, useState } from "react"
import type { Subscription } from "rxjs"
import { useDebounceCallback } from "usehooks-ts"
import { Box, LinearProgress } from "@mui/material"

import { sync } from "../model/sync"
import { database } from "../model/database"
import { TableName } from "../model/schema"
import { SignalRContext } from "../App"
import SettingsContext from "../contexts/settings.context"
import { PING_ENDPOINT } from "../constants/commonStrings.constant"

declare global {
  interface Navigator {
    connection?: {
      addEventListener: (type: string, callback: () => void) => void
      removeEventListener: (type: string, callback: () => void) => void
      type: string
      effectiveType: string
    }
  }
}

interface SyncOptions {
  reset?: boolean
  broadcast?: boolean
}

export interface SyncContextType {
  isSyncing: boolean
  isOnline: boolean
  queueSync: (options?: SyncOptions) => void
  reset: () => void
}

export const SyncContext = createContext<SyncContextType>({
  isSyncing: false,
  isOnline: true,
  queueSync: () => {},
  reset: () => {},
})

const SYNC_DEBOUNCE_MS = 1000
const RESET_DELAY_MS = 1000

async function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

// Network status check function
async function checkNetworkStatus(): Promise<boolean> {
  try {
    const response = await fetch(PING_ENDPOINT, {
      method: "HEAD",
      cache: "no-cache",
      headers: { "Cache-Control": "no-cache" },
    })
    return response.ok
  } catch {
    return false
  }
}

export function SyncProvider({ children }: { children: ReactNode }) {
  // Change this default to true to force a reset on startup
  const [isReadyToReset, setIsReadyToReset] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [isSyncQueued, setIsSyncQueued] = useState(false)
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const [isResetting, setIsResetting] = useState(false)

  const syncInProgress = useRef(false)

  const { activeProject } = useContext(SettingsContext).settingsState

  SignalRContext.useSignalREffect(
    "DeviceUpdate", // Your Event Key
    // @ts-ignore
    (payload: any) => {
      console.log(`DeviceUpdate: SyncProvider`, payload)
      queueSync()
    },
    [],
  )

  // Network status handlers
  useEffect(() => {
    const connection = navigator.connection

    const handleConnectionChange = () => {
      console.log("Connection change detected")
      checkNetworkStatus().then((status) => {
        setIsOnline(status)
        if (status) {
          queueSync()
        }
      })
    }

    const handleOnline = async () => {
      const status = await checkNetworkStatus()
      setIsOnline(status)
      if (status) {
        console.log("Online event: Device is online")
        queueSync()
      }
    }

    const handleOffline = () => {
      setIsOnline(false)
      console.log("Offline event: Device is offline")
    }

    // Listen for both standard and mobile-specific events
    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    if (connection) {
      connection.addEventListener("change", handleConnectionChange)
    }

    // Initial network check
    checkNetworkStatus().then((status) => {
      setIsOnline(status)
      if (status) {
        queueSync()
      }
    })

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
      if (connection) {
        connection.removeEventListener("change", handleConnectionChange)
      }
    }
  }, [])

  // Visibility change handler
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        console.log("queueSync: visibilityState is visible")
        queueSync()
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange)
    console.log("queueSync: Initial sync")
    queueSync() // Initial sync

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [])

  // Periodic network check
  useEffect(() => {
    let networkCheckInterval: NodeJS.Timeout

    const checkAndUpdateStatus = async () => {
      const isNetworkAvailable = await checkNetworkStatus()
      if (isNetworkAvailable !== isOnline) {
        setIsOnline(isNetworkAvailable)
        if (isNetworkAvailable) {
          console.log("Network check: Device is online")
          queueSync()
        }
      }
    }

    // Check network status every 30 seconds when in foreground
    if (document.visibilityState === "visible") {
      networkCheckInterval = setInterval(checkAndUpdateStatus, 30000)
    }

    return () => {
      if (networkCheckInterval) {
        clearInterval(networkCheckInterval)
      }
    }
  }, [isOnline])

  const debouncedSync = useDebounceCallback(queueSync, SYNC_DEBOUNCE_MS)

  // Sync on project change
  useEffect(() => {
    if (activeProject) {
      executeSync(true)
    }
  }, [activeProject])

  // Listen for db events as long as we're not resetting
  useEffect(() => {
    if (isResetting) {
      setIsReadyToReset(true)
      return
    }

    const subscriptions: Subscription[] = []

    // Convert TableName enum to an array of table names
    const tableNames = Object.values(TableName)
    tableNames.forEach((tableName) => {
      const subscription = database.withChangesForTables([tableName]).subscribe({
        next: (changes) => {
          const changedRecords = changes?.filter((c) => c.record.syncStatus !== "synced")

          if (changes?.length || changedRecords?.length) {
            console.log(`♻️ Database changes for ${tableName}:`, changes?.length, changedRecords?.length)
          }

          if (changedRecords?.length) {
            // const debouncedSync = debounce(() => queueSync(), syncDelay)
            console.log("♻️ Debouncing sync")
            debouncedSync()
          }
        },
        error: (error) => console.error("♻️ Database changes error", error),
      })

      subscriptions.push(subscription)
      console.log(`♻️ Subscribed to database changes for ${tableName}`)
    })

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe())
      console.log("Unsubscribed from database changes")
    }
  }, [database, isResetting])

  function queueSync() {
    setIsSyncQueued(true)
    console.log("queueSync", { isSyncing, isSyncQueued })
  }

  // If not syncing but sync is queued, execute sync
  useEffect(() => {
    if (!isOnline) {
      console.log("Device is offline, skipping sync")
      return
    }

    if (!isSyncing && isSyncQueued && !syncInProgress.current) {
      executeSync()
    }
  }, [isSyncing, isSyncQueued, isOnline])

  // To reset, set isResetting to true, which will unmount all screens and set isReadyToReset to true via onLayout
  function reset() {
    setIsResetting(true)
  }

  // If ready to reset and not syncing, execute sync
  useEffect(() => {
    if (isReadyToReset && !isSyncing && !syncInProgress.current) {
      console.log("Ready to reset")
      executeSync(true)
    }
  }, [isReadyToReset, isSyncing])

  async function executeSync(reset?: boolean) {
    if (syncInProgress.current) {
      console.log("Sync already in progress, skipping")
      return
    }

    let syncProject = activeProject
    if (!activeProject) {
      syncProject = {
        projectId: 0,
        createdAt: +new Date(),
        createdBy: "system",
      }
    }

    try {
      syncInProgress.current = true
      if (reset) setIsReadyToReset(false)
      setIsSyncQueued(false)
      setIsSyncing(true)
      // If this is a reset, delay sync call by one second to give the app time to umount all screens
      if (reset) {
        await delay(RESET_DELAY_MS)
      }
      console.log("♻️ Syncing", { reset })
      await sync({ reset, project: syncProject })
      console.log("♻️ Sync succeeded", { reset })
    } catch (error) {
      console.error("♻️ Sync failed:", error)
      // Optional: Implement retry logic here
    } finally {
      setIsSyncing(false)
      if (reset) setIsResetting(false)
      syncInProgress.current = false
    }
  }

  return isResetting ? (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
      <Box sx={{ p: 2, textAlign: "center" }}>Syncing...</Box>
    </Box>
  ) : (
    <SyncContext.Provider
      value={{
        isSyncing,
        isOnline,
        queueSync,
        reset,
      }}>
      {children}
    </SyncContext.Provider>
  )
}
