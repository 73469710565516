import type { IDevice } from "../interfaces/device.interface"
import { Q } from "@nozbe/watermelondb"
import { database } from "../model/database"
import type Device from "../model/Device"
import { TableName } from "../model/schema"
import type { IUser } from "../interfaces"

class DeviceService {
  async getDevices(): Promise<IDevice[]> {
    const devices = await database.collections
      .get<Device>(TableName.Device)
      .query(Q.where("deletedAt", Q.eq(null)))
      .fetch()
    return devices.map((device) => this.mapDevice(device))
  }

  async getDeviceById(id: number): Promise<IDevice> {
    const device = await database.collections.get<Device>(TableName.Device).query(Q.where("deviceId", id)).fetch()
    if (device.length === 0) {
      throw new Error("Device not found")
    }
    return this.mapDevice(device[0])
  }

  async addDevice(payload: IDevice): Promise<IDevice> {
    const device = await database.write(async () => {
      return database.collections.get<Device>(TableName.Device).create((record) => {
        record.deviceId = payload.deviceId || 0
        record.deviceName = payload.deviceName
        record.deviceDescription = payload.deviceDescription
        record.deviceSerialNumber = payload.deviceSerialNumber
        record.projectId = payload.projectId || 0
      })
    })
    return this.mapDevice(device)
  }

  async getDeviceBySerialNumber(deviceSerialNumber: string): Promise<IDevice> {
    const devices = await database.collections
      .get<Device>(TableName.Device)
      .query(Q.where("deviceSerialNumber", deviceSerialNumber))
      .fetch()
    if (devices.length === 0) {
      throw new Error("Device not found")
    }
    return this.mapDevice(devices[0])
  }

  async getDeviceForLocalUser(): Promise<IDevice> {
    const storedUser: string | null = localStorage.getItem("user")
    const user: IUser | null = storedUser && JSON.parse(storedUser)

    if (!user || !user.email) {
      throw new Error("User not found")
    }
    const devices = await database.collections
      .get<Device>(TableName.Device)
      .query(Q.where("deviceSerialNumber", `${user.email}-mobile`))
      .fetch()
    if (devices.length === 0) {
      const newDevice = await this.addDevice({
        deviceName: `${user.email}-mobile`,
        deviceDescription: "Mobile device",
        deviceSerialNumber: `${user.email}-mobile`,
        createdBy: user.email,
      })
      return newDevice
    }
    return this.mapDevice(devices[0])
  }

  async scan(serialNumber: string, dateCutoff: string = ""): Promise<IDevice[]> {
    let query = database.collections.get<Device>(TableName.Device).query(Q.where("deviceSerialNumber", serialNumber))

    if (dateCutoff) {
      query = query.extend(Q.where("createdAt", Q.gte(new Date(dateCutoff).getTime())))
    }

    const devices = await query.fetch()
    return devices.map((device) => this.mapDevice(device))
  }

  private mapDevice(device: Device): IDevice {
    return {
      deviceId: device.deviceId,
      deviceName: device.deviceName,
      deviceDescription: device.deviceDescription,
      deviceSerialNumber: device.deviceSerialNumber,
      createdAt: device.createdAt,
      createdBy: device.createdBy,
      createdOffset: device.createdOffset,
      updatedAt: device.updatedAt,
      updatedBy: device.updatedBy,
      updatedOffset: device.updatedOffset,
      deletedAt: device.deletedAt,
      deletedBy: device.deletedBy,
      deletedOffset: device.deletedOffset,
      projectId: device.projectId,
      id: device.id,
    }
  }
}

export const deviceService = new DeviceService()
