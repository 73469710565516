import { Model, Relation } from "@nozbe/watermelondb"
import { field, readonly, date, text, relation } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"
import type { Associations } from "@nozbe/watermelondb/Model"
import type Feature from "./Feature"

export default class Attachment extends Model {
  static table = TableName.Attachment

  static associations: Associations = {
    [TableName.Feature]: { type: "belongs_to", key: "objectKey" },
  }

  @field("formId") formId?: number
  @field("attachmentId") attachmentId?: number
  @field("orientation") orientation?: number
  @field("longitude") longitude?: number
  @field("latitude") latitude?: number
  @text("path") path?: string
  @text("name") name?: string
  @text("fileName") fileName?: string
  @text("data") data?: string
  @text("url") url?: string
  @text("fieldId") fieldId?: string
  @text("type") type?: string
  @text("featureKey") featureKey?: string
  @text("objectName") objectName?: string
  @text("objectKey") objectKey?: string
  @field("objectId") objectId?: number
  @text("targetRefKey") targetRefKey?: string
  @text("thumbnailUrl") thumbnailUrl?: string

  @readonly @date("created_at") createdAt!: number
  @readonly @date("updated_at") updatedAt?: number

  @relation(TableName.Feature, "objectKey") feature?: Relation<Feature>
}
