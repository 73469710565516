export class FormDataParser {
  static parseFormData(formDataString: string): Record<string, string | null> {
    const result: Record<string, string | null> = {}

    try {
      const formData = JSON.parse(formDataString)

      const processNode = (node: any) => {
        if (
          !(node.type === "section" || node.type === "header" || node.type === "camera") &&
          node.label &&
          "value" in node
        ) {
          if (Array.isArray(node.value)) {
            result[node.label] = node.value.join(", ")
          } else {
            result[node.label] = node.value ?? ""
          }
        }

        if (node.children && Array.isArray(node.children)) {
          node.children.forEach(processNode)
        }
      }

      formData.forEach(processNode)
    } catch (error) {
      console.error("Error parsing form data:", error, formDataString)
    }

    return result
  }
}
