import {
  Box,
  CircularProgress,
  Container,
  List,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
  ListItemButton,
  Stack,
  Chip,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Badge,
} from "@mui/material"
import React, { useState, useCallback, useEffect, useContext } from "react"

import SettingsContext from "../../contexts/settings.context"
import { HiGlobeAmericas, HiMagnifyingGlass } from "react-icons/hi2"
import { VscFilter, VscTools } from "react-icons/vsc"
import { UPDATE_SETTINGS } from "../../contexts/reducers/settings.reducer"
import { hexToRGB } from "../../components/FormBuilder/utilities"
import type { IProject } from "../../interfaces"
import { projectService } from "../../services"
import { useNavigate } from "react-router-dom"
import LayoutContext from "../../contexts/layout.context"
import { SheetTopBar } from "../../components"

function SelectProjectList() {
  const theme = useTheme()
  const [projects, setProjects] = useState<IProject[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>("")
  const { settingsState, updateSettings, resetFilters } = useContext(SettingsContext)
  const { closeSidebar } = useContext(LayoutContext)
  const navigate = useNavigate()

  const fetch = useCallback(async () => {
    setIsLoading(true)
    try {
      const res = await projectService.getAll(settingsState.activeWorld?.worldId)
      if (res) {
        setProjects(res)
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    fetch()
  }, [])

  const handleOnSelect = (project: IProject) => {
    updateSettings(UPDATE_SETTINGS, {
      ...settingsState,
      activeProject: project,
      activeSurvey: undefined,
      activeFeature: undefined,
      sidebar: null,
    })

    resetFilters()

    navigate("/")
  }

  const renderLoadingContainer = () => (
    <Box width={"100%"} display={"flex"} justifyContent={"center"} py={12} alignItems={"center"}>
      <CircularProgress />
    </Box>
  )

  const renderSearchBox = () => {
    return (
      <Stack flexDirection={"row"} py={2} alignItems={"center"} gap={1}>
        <Box
          width={"100%"}
          display={"flex"}
          gap={1}
          border={`1px solid ${theme.palette.divider}`}
          bgcolor={theme.palette.background.paper}
          p={0.2}
          height={44}
          borderRadius={3}
          mt={-2.2}
          mb={-3.2}>
          <FormControl
            fullWidth
            size="small"
            variant="outlined"
            sx={{
              "& fieldset": {
                border: "none !important",
              },
            }}>
            <OutlinedInput
              id="outlined-adornment-password"
              type={"text"}
              //sx={{ bgcolor: theme.palette.background.default }}
              placeholder="Search..."
              onChange={(event) => setSearchText(event.target.value)}
              value={searchText}
              startAdornment={
                <InputAdornment position="start">
                  <HiMagnifyingGlass />
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Stack>
    )
  }

  const filteredProjects = projects
    .filter((project) => project.projectName?.toLowerCase().includes(searchText.toLowerCase()))
    .sort((a, b) => (a.projectName || "").localeCompare(b.projectName || ""))

  return (
    <>
      <SheetTopBar
        onClose={() => closeSidebar()}
        avatar={<VscTools color="white" size={36} opacity={0.7} />}
        leading={
          <Stack gap={0}>
            <Typography color={"white"} fontWeight={900}>
              Projects
            </Typography>
          </Stack>
        }
      />

      <Container maxWidth={false} sx={{ pt: 2, marginBottom: 7 }}>
        {isLoading ? (
          renderLoadingContainer()
        ) : (
          <>
            {renderSearchBox()}
            <Box
              sx={{
                my: 2,
                background: theme.palette.background.paper,
                borderRadius: 3,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: theme.palette.grey[300],
                overflow: "hidden",
              }}>
              <List sx={{ paddingY: 1, marginBottom: -1 }} dense>
                {filteredProjects &&
                  filteredProjects.map((project, index) => {
                    return (
                      <ListItemButton
                        dense
                        sx={{ cursor: "pointer" }}
                        key={index}
                        onClick={() => handleOnSelect(project)}>
                        <ListItemAvatar sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                          <HiGlobeAmericas color={theme.palette.primary.main} size={32} />
                        </ListItemAvatar>

                        <ListItemText
                          primaryTypographyProps={{ fontWeight: "bold" }}
                          secondary={"Project"}
                          secondaryTypographyProps={{ fontSize: 12 }}
                          primary={project.projectName}
                        />
                        {settingsState.activeProject && settingsState.activeProject.projectId === project.projectId && (
                          <Chip
                            size="small"
                            sx={{
                              backgroundColor: hexToRGB(theme.palette.success.main, 0.1),
                              color: theme.palette.success.main,
                            }}
                            label="Active"
                          />
                        )}
                      </ListItemButton>
                    )
                  })}
              </List>
            </Box>
          </>
        )}
      </Container>
    </>
  )
}

export default SelectProjectList
