import { Model } from "@nozbe/watermelondb"
import type { Associations } from "@nozbe/watermelondb/Model"
import { field, readonly, date } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"

export default class Device extends Model {
  static table = "devices"

  static associations: Associations = {
    [TableName.Point]: { type: "has_many", foreignKey: "deviceKey" },
  }

  @field("deviceId") deviceId!: number
  @field("deviceName") deviceName?: string
  @field("deviceDescription") deviceDescription?: string
  @field("deviceSerialNumber") deviceSerialNumber?: string
  @field("createdBy") createdBy?: string
  @field("createdOffset") createdOffset?: number
  @field("updatedBy") updatedBy?: string
  @field("updatedOffset") updatedOffset?: number
  @readonly @date("deletedAt") deletedAt?: number
  @field("deletedBy") deletedBy?: string
  @field("deletedOffset") deletedOffset?: number
  @field("projectId") projectId?: number
  @readonly @date("created_at") createdAt!: number
  @readonly @date("updated_at") updatedAt?: number
}
