import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"

import React, { useCallback, useContext, useEffect, useState } from "react"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

import SettingsContext from "../../../contexts/settings.context"
import { Add, Settings, TimelineOutlined } from "@mui/icons-material"
import { hexToRGB } from "../../FormBuilder/utilities"
import { UPDATE_SETTINGS } from "../../../contexts/reducers/settings.reducer"
import { formService } from "../../../services"
import type { IFeature, IFeatureStyled, IFormTemplate } from "../../../interfaces"
import { featureService } from "../../../services/feature.service"
import { useNavigate } from "react-router-dom"
import { changeSvgColor } from "../../../utils/image.util"
import FeatureDialog from "../FeatureDialog/FeatureDialog.component"
import FeatureDetail from "../../../pages/Features/FeatureDetail.page"
import { VscAdd } from "react-icons/vsc"
import { HiChevronRight, HiOutlineMapPin } from "react-icons/hi2"
import { grey } from "@mui/material/colors"
import { HiOutlineCamera } from "react-icons/hi"
import { GoChecklist } from "react-icons/go"
import LayoutContext from "../../../contexts/layout.context"
import SheetTopBar from "../../Atoms/SheetTopBar/SheetTopBar.component"

function FeatureSelectorSheet() {
  const [features, setFeatures] = useState<IFeature[]>([])
  const [featuresQuickAdd, setFeaturesQuickAdd] = useState<IFeatureStyled[]>([])
  const { settingsState, updateSettings } = useContext(SettingsContext)
  const { openSidebar, closeSidebar } = useContext(LayoutContext)
  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false)
  const [forms, setForms] = useState<IFormTemplate[]>([])
  const [activeFeature, setActiveFeature] = useState<IFeatureStyled>()
  const theme = useTheme()
  const navigate = useNavigate()
  const open = Boolean(activeFeature)

  dayjs.extend(utc)

  const fetchFeatures = useCallback(async () => {
    try {
      if (settingsState.activeProject && settingsState.activeProject.projectId) {
        const _features = await featureService.getFeaturesStyled({
          projectId: settingsState.activeProject.projectId,
          surveyKey: settingsState.activeSurvey?.id,
        })

        if (_features) setFeatures(_features.filter((feature) => feature.status !== "completed"))
      }
    } catch (error) {
      console.log(error)
    }
  }, [
    settingsState.activeProject,
    settingsState.surveysFilter.selectedForm,
    settingsState.surveysFilter.selectedTimeFilter,
  ])

  const fetchFeaturesStyled = useCallback(async () => {
    const _featuresQuickAdd = await featureService.getFeaturesQuickAdds({
      projectId: settingsState.activeProject?.projectId ?? 0,
      surveyKey: settingsState.activeSurvey?.id,
    })
    if (_featuresQuickAdd) setFeaturesQuickAdd(_featuresQuickAdd)
  }, [])

  const fetchForms = useCallback(async () => {
    const _forms = await formService.getForms()
    if (_forms) {
      setForms(_forms)
    }
  }, [])

  useEffect(() => {
    fetchFeatures()
    fetchFeaturesStyled()
    fetchForms()
  }, [])

  const handleFilterChange = (field: string, value: any) => {
    updateSettings(UPDATE_SETTINGS, {
      ...settingsState,
      surveysFilter: {
        ...settingsState.surveysFilter,
        [field]: value,
      },
    })
  }

  const handleOnSaveFeature = async (feature: IFeature, form?: IFormTemplate) => {
    if (form) {
      await featureService.addFeature(
        {
          ...feature,
          projectId: settingsState.activeProject?.projectId,
          formId: form?.formId,
          formTitle: form?.formTitle,
          status: "started",
        },
        form,
      )
      fetchFeatures()
    }
  }

  const renderFeaturesQuickAdd = () => (
    <Stack py={2} gap={1} alignItems={"start"} position={"relative"} width={"100%"}>
      <Typography fontWeight={700} color={grey[700]} pl={3} fontSize={14}>
        Add Feature
      </Typography>
      <Stack
        px={2}
        direction={"row"}
        gap={1}
        sx={{
          width: "100%",
          overflowX: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}>
        {featuresQuickAdd.map((feat, index) => {
          return (
            <React.Fragment key={index}>
              <Chip
                style={{ height: 40, borderRadius: 24, borderColor: theme.palette.primary.main }}
                sx={{ fontWeight: "bold", px: 0.5 }}
                label={feat.formTitle}
                onDelete={() => {}}
                onClick={() =>
                  setActiveFeature({
                    surveyId: settingsState.activeSurvey?.surveyId ?? -1,
                    projectId: settingsState.activeProject?.projectId,
                    surveyKey: settingsState.activeSurvey?.id,
                    featureName: feat.formTitle ?? "",
                    formId: feat.formId,
                    status: "started",
                  } as IFeatureStyled)
                }
                avatar={
                  <Box width={24} height={24}>
                    {renderFeatureIcon(feat)}
                  </Box>
                }
                deleteIcon={<VscAdd size={16} color={theme.palette.primary.main} strokeWidth={1} />}
                variant="outlined"
              />
            </React.Fragment>
          )
        })}
        <Box
          sx={{
            background: "linear-gradient(90deg, rgba(255,255,255, 0.01) 0%, rgba(255,255,255,1) 30%)",
          }}
          position={"absolute"}
          right={0}
          top={0}
          height={"100%"}
          width={"25%"}
        />
        <Chip
          style={{ height: 40, borderRadius: 24 }}
          sx={{
            position: "sticky",
            right: 0,
            px: 0.5,
            fontWeight: "bold",
            "& .MuiSvgIcon-root": {
              backgroundColor: "transparent",
            },
          }}
          color="primary"
          label={"Feature"}
          onClick={() =>
            setActiveFeature({
              surveyId: settingsState.activeSurvey?.surveyId ?? -1,
              surveyKey: settingsState.activeSurvey?.id,
              projectId: settingsState.activeProject?.projectId,
              featureName: "",
            } as IFeatureStyled)
          }
          avatar={<Add />}
        />
      </Stack>
    </Stack>
  )

  const renderLoading = () => (
    <Stack gap={1} direction={"row"}>
      {Array.from(Array(3).keys()).map((index) => (
        <Stack gap={1} key={index}>
          <Skeleton variant="rectangular" width={150} height={80}></Skeleton>
          <Skeleton variant="rectangular" width={80} height={12}></Skeleton>
          <Skeleton variant="rectangular" width={120} height={16}></Skeleton>
        </Stack>
      ))}
    </Stack>
  )

  const renderSearchFilters = (direction: "row" | "column" = "row") => (
    <Stack gap={1} direction={direction}>
      <FormControl size="small" sx={{ p: 0, m: 0, minWidth: 120 }} variant="outlined">
        <InputLabel id="form-status-label">When</InputLabel>
        <Select
          size="small"
          labelId="form-status-label"
          id="form-status-select"
          label="When"
          value={settingsState.surveysFilter.selectedTimeFilter}
          sx={{
            "& .MuiSelect-select": {
              fontSize: 14,
            },
          }}
          onChange={(event) => {
            handleFilterChange("selectedTimeFilter", event.target.value)
          }}>
          {/* {featureService.featureTimeFilters &&
            featureService.featureTimeFilters.map((filter, index) => {
              return (
                <MenuItem key={index} value={filter.id}>
                  {filter.name}
                </MenuItem>
              )
            })} */}
        </Select>
      </FormControl>
      {forms && forms.length && (
        <FormControl size="small" sx={{ p: 0, m: 0, minWidth: 120 }} variant="outlined">
          <InputLabel id="form-status-label">Form</InputLabel>
          <Select
            size="small"
            labelId="form-status-label"
            id="form-status-select"
            label="Form"
            value={settingsState.surveysFilter.selectedForm}
            sx={{
              "& .MuiSelect-select": {
                fontSize: 14,
              },
            }}
            onChange={(event) => {
              handleFilterChange("selectedForm", event.target.value)
            }}>
            <MenuItem value={-1}>All</MenuItem>
            {forms.map((form, index) => {
              return (
                <MenuItem key={index} value={form.formId}>
                  {form.formTitle}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      )}
    </Stack>
  )

  const renderFilterDialog = () => (
    <Dialog
      open={openFilterDialog}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.background.default,
        },
      }}
      fullWidth>
      <DialogTitle display={"flex"} alignItems={"center"}>
        <Settings />
        <Typography sx={{ marginLeft: 1 }}>Filter</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          backgroundColor: theme.palette.background.paper,
        }}>
        <Box display={"flex"} flexDirection={"column"} gap={1.5}>
          {renderSearchFilters("column")}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button
          size="small"
          id="cancel"
          variant="outlined"
          sx={{ fontWeight: "medium" }}
          onClick={() => {
            setOpenFilterDialog(false)
          }}>
          Cancel
        </Button>
        <Button
          disableElevation
          size="small"
          variant="contained"
          id="save-close"
          onClick={async () => {
            setOpenFilterDialog(false)
          }}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )

  const renderStatusColor = (feature: IFeature | IFeatureStyled) => {
    const color: any = featureService.getStatusThemeColorAlt(feature.status)
    return color
  }

  const renderItem = (feature: IFeatureStyled) => (
    <ListItemButton
      dense
      sx={{
        cursor: "pointer",
        backgroundColor:
          settingsState.activeFeature && settingsState.activeFeature?.id === feature.id
            ? hexToRGB(theme.palette.primary.main, 0.05)
            : "transparent",
        borderLeft: `4px solid ${settingsState.activeFeature && settingsState.activeFeature?.id === feature.id ? theme.palette.primary.main : "transparent"}`,
      }}
      onClick={() => {
        if (settingsState.activeSurvey)
          openSidebar(
            <FeatureDetail
              feature={feature}
              survey={settingsState.activeSurvey}
              featureKey={feature.id}
              goBack={() => openSidebar(<FeatureSelectorSheet />)}
              onSave={async () => {
                await fetchFeatures()
                // Check if all the features are completed
                // If so, then navigate to Survey Detail page.
              }}
            />,
            "full",
          )
      }}
      key={feature.id}>
      <ListItemAvatar
        sx={{
          display: "flex",
          justifyContent: "start",
          minWidth: 24,
          marginRight: 1,
          alignItems: "center",
        }}>
        <Box sx={{ width: 30, height: 30 }}>{renderFeatureIcon(feature)}</Box>
      </ListItemAvatar>

      <ListItemText
        secondary={
          <small>
            {feature.formTitle} | <i>{feature.workTypeName}</i> | <i>{feature.featureTypes}</i>
          </small>
        }
        secondaryTypographyProps={{ fontSize: 14 }}
        primaryTypographyProps={{
          fontWeight: 900,
        }}
        primary={feature.featureName}
      />

      <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
        {settingsState.activeFeature && settingsState.activeFeature?.id === feature.id && (
          <Chip
            size="small"
            sx={{
              backgroundColor: hexToRGB(theme.palette.info.main, 0.1),
              color: theme.palette.info.main,
            }}
            label="Active"
          />
        )}
        <Chip
          size="small"
          sx={{
            backgroundColor: renderStatusColor(feature).backgroundColor,
            color: renderStatusColor(feature).color,
            borderColor: renderStatusColor(feature).borderColor,
            borderWidth: 1,
            borderStyle: "solid",
            textTransform: "capitalize",
            fontSize: 12,
          }}
          label={`${feature?.status}`}
        />

        <Badge
          badgeContent={feature.fileCount}
          max={999}
          slotProps={{
            badge: {
              style: { fontSize: 10, padding: 1 },
            },
          }}
          color="error">
          <HiOutlineCamera size={20} />
        </Badge>
        <Badge
          badgeContent={feature.pointCount}
          slotProps={{
            badge: {
              style: { fontSize: 10, padding: 1 },
            },
          }}
          color="error">
          <HiOutlineMapPin size={20} />
        </Badge>
        <HiChevronRight strokeWidth={1} />
      </Stack>
    </ListItemButton>
  )

  const renderFeatureIcon = (feature: IFeatureStyled) => {
    if (feature.icon) {
      if (feature.icon.toLowerCase().includes("svg")) {
        const coloredSvg = changeSvgColor(feature.icon, feature.iconColor ?? "#111")
        return <span dangerouslySetInnerHTML={{ __html: coloredSvg }} />
      }
      return <Avatar style={{ width: "100%", height: "100%" }} src={feature.icon} alt="" />
    }
    return (
      <Avatar alt="">
        <TimelineOutlined />
      </Avatar>
    )
  }

  const renderSurveyCard = () => (
    <ListItemButton
      dense
      sx={{ cursor: "pointer" }}
      onClick={() => {
        navigate(`/project/jobs/${settingsState.activeSurvey?.id}`)
        closeSidebar()
      }}>
      <ListItemAvatar
        sx={{
          display: "flex",
          justifyContent: "start",
          minWidth: 24,
          marginRight: 1,
          alignItems: "center",
        }}>
        <GoChecklist color={theme.palette.primary.main} size={24} />
      </ListItemAvatar>
      {settingsState.activeSurvey && (
        <ListItemText
          secondary={"Active Survey"}
          secondaryTypographyProps={{ fontSize: 14 }}
          primaryTypographyProps={{ fontWeight: 700 }}
          primary={settingsState.activeSurvey.surveyName}
        />
      )}
      <HiChevronRight strokeWidth={1} />
    </ListItemButton>
  )

  return (
    <Box position={"relative"}>
      <Stack>
        <SheetTopBar
          onClose={() => closeSidebar()}
          avatar={<HiOutlineMapPin size={28} color="white" opacity={0.7} />}
          leading={
            <Stack gap={0}>
              <Typography color={"white"} style={{ opacity: 0.7 }} typography="caption">
                Features Selector
              </Typography>
              {settingsState.activeSurvey && (
                <Box
                  style={{
                    cursor: "pointer",
                    color: theme.palette.primary.main,
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    navigate(`/project/jobs/${settingsState.activeSurvey?.id}`)
                    closeSidebar()
                  }}>
                  <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                    <Typography sx={{ "&:hover": { textDecoration: "underline" } }} color={"white"} fontWeight={900}>
                      {settingsState.activeSurvey?.surveyName}
                    </Typography>
                    <HiChevronRight strokeWidth={1} color="white" />
                  </Stack>
                </Box>
              )}
            </Stack>
          }
        />

        {renderFeaturesQuickAdd()}
        <Stack gap={1.5} px={2}>
          {settingsState.activeSurvey && (
            <Box
              sx={{
                borderRadius: 3,
                overflow: "hidden",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: grey[300],
              }}>
              {renderSurveyCard()}
            </Box>
          )}

          <Box>
            <Typography fontSize={16} fontWeight={"bold"} marginBottom={1}>
              In Progress ({features?.length})
            </Typography>
            <Box
              sx={{
                borderRadius: 3,
                overflow: "hidden",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: grey[300],
              }}>
              {features ? features.map(renderItem) : renderLoading()}
            </Box>
          </Box>
        </Stack>
      </Stack>
      {renderFilterDialog()}
      {activeFeature && (
        <FeatureDialog
          handleSave={handleOnSaveFeature}
          onDelete={() => {}}
          handleClose={() => setActiveFeature(undefined)}
          open={open}
          feature={activeFeature}
          mode="Create"
        />
      )}
    </Box>
  )
}

export default FeatureSelectorSheet
