import {
  Box,
  CircularProgress,
  Container,
  List,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
  ListItemButton,
  IconButton,
  Stack,
  Chip,
  Tooltip,
  Avatar,
  Icon,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material"
import React, { useState, useCallback, useEffect, useContext } from "react"

import SettingsContext from "../../contexts/settings.context"
import { VscClose } from "react-icons/vsc"
import { UPDATE_SETTINGS } from "../../contexts/reducers/settings.reducer"
import { hexToRGB } from "../../components/FormBuilder/utilities"
import type { ISurvey } from "../../interfaces"
import { surveyService } from "../../services/survey.service"
import { GoChecklist } from "react-icons/go"
import { pascalCaseToUnderscore } from "../../utils/string.util"
import LayoutContext from "../../contexts/layout.context"
import { HiMagnifyingGlass } from "react-icons/hi2"

function SelectSurveytList() {
  const theme = useTheme()
  const [surveys, setSurveys] = useState<ISurvey[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { settingsState, updateSettings } = useContext(SettingsContext)
  const { closeSidebar } = useContext(LayoutContext)
  const [searchText, setSearchText] = useState<string>("")

  const fetch = useCallback(async () => {
    setIsLoading(true)
    try {
      if (settingsState.activeProject) {
        const res = await surveyService.search("all", settingsState.activeProject.projectId ?? 0, "")
        if (res) {
          setSurveys(res)
          setIsLoading(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    fetch()
  }, [])

  const handleOnSelect = (survey: ISurvey) => {
    updateSettings(UPDATE_SETTINGS, {
      ...settingsState,
      activeSurvey: {
        surveyId: survey.surveyId,
        id: survey.id,
        surveyName: survey.surveyName,
        formTitle: survey.formTitle,
        workTypeName: survey.workTypeName,
        workTypeId: survey.workTypeId,
      },
      projectMapFilter: {
        ...settingsState.projectMapFilter,
        selectedSurvey: survey.surveyId ?? -1,
      },
      featuresFilter: {
        ...settingsState.featuresFilter,
        selectedSurvey: survey.id ?? "",
      },
      pointsFilter: {
        ...settingsState.pointsFilter,
        selectedSurvey: survey.id ?? "",
      },
      activeFeature: undefined,
      sidebar: null,
    })
  }

  const renderSearchBox = () => {
    return (
      <Stack flexDirection={"row"} py={2} alignItems={"center"} gap={1}>
        <Box
          width={"100%"}
          display={"flex"}
          gap={1}
          border={`1px solid ${theme.palette.divider}`}
          bgcolor={theme.palette.background.paper}
          p={0.2}
          height={44}
          borderRadius={3}
          mt={-2.2}
          mb={-3.2}>
          <FormControl
            fullWidth
            size="small"
            variant="outlined"
            sx={{
              "& fieldset": {
                border: "none !important",
              },
            }}>
            <OutlinedInput
              id="outlined-adornment-password"
              type={"text"}
              //sx={{ bgcolor: theme.palette.background.default }}
              placeholder="Search..."
              onChange={(event) => setSearchText(event.target.value)}
              value={searchText}
              startAdornment={
                <InputAdornment position="start">
                  <HiMagnifyingGlass />
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Stack>
    )
  }

  const filteredSurveys = surveys
    .filter((survey) => survey.surveyName?.toLowerCase().includes(searchText.toLowerCase()))
    .sort((a, b) => (a.surveyName || "").localeCompare(b.surveyName || ""))

  const renderLoadingContainer = () => (
    <Box width={"100%"} display={"flex"} justifyContent={"center"} py={12} alignItems={"center"}>
      <CircularProgress />
    </Box>
  )
  const renderStatusColor = (survey: ISurvey) => {
    const color: any = surveyService.getStatusThemeColorAlt(survey.status)
    return color
  }

  return (
    <>
      <Stack position={"sticky"} top={0} zIndex={50}>
        <Box
          sx={{
            px: 2,
            py: 1,
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-between",
            background: "linear-gradient(-90deg, #0c355f 2%, rgba(26,47,68,1) 62%);",
            backdropFilter: "blur(10px)",
          }}>
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            <GoChecklist color="white" size={36} opacity={0.7} />
            <Stack gap={0}>
              <Typography color={"white"} fontWeight={900}>
                Jobs
              </Typography>
            </Stack>
          </Stack>
          <Stack gap={1} flexDirection={"row"}>
            <IconButton onClick={() => closeSidebar()} sx={{ color: "white" }}>
              <VscClose />
            </IconButton>
          </Stack>
        </Box>
      </Stack>
      <Container maxWidth={false} sx={{ pt: 2, marginBottom: 7 }}>
        {isLoading ? (
          renderLoadingContainer()
        ) : (
          <>
            {renderSearchBox()}
            <Box
              sx={{
                my: 2,
                background: theme.palette.background.paper,
                borderRadius: 3,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: theme.palette.grey[300],
                overflow: "hidden",
              }}>
              <List sx={{ paddingY: 1, marginBottom: -1 }} dense>
                {filteredSurveys &&
                  filteredSurveys.map((survey, index) => {
                    const StatusIcon = surveyService.getStatusIcon(
                      survey?.status,
                      settingsState.activeSurvey && settingsState.activeSurvey.id === survey.id,
                    )

                    return (
                      <ListItemButton
                        dense
                        sx={{ cursor: "pointer" }}
                        key={index}
                        onClick={() => handleOnSelect(survey)}>
                        <ListItemAvatar sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                          <Tooltip title={survey.status}>
                            <Avatar
                              sx={{
                                bgcolor: surveyService.getStatusColor(
                                  survey.status,
                                  settingsState.activeSurvey && settingsState.activeSurvey.id === survey.id,
                                ),
                              }}>
                              <StatusIcon fontSize="small" />
                            </Avatar>
                          </Tooltip>
                        </ListItemAvatar>

                        <ListItemText
                          primaryTypographyProps={{ fontWeight: "bold" }}
                          secondary={
                            survey.formTitle &&
                            survey.workTypeName && (
                              <small>
                                {survey.formTitle} | <i>{survey.workTypeName}</i>
                              </small>
                            )
                          }
                          secondaryTypographyProps={{ fontSize: 12 }}
                          primary={survey.surveyName}
                        />
                        {settingsState.activeSurvey && settingsState.activeSurvey?.id === survey.id && (
                          <Chip
                            size="small"
                            sx={{
                              backgroundColor: hexToRGB(theme.palette.info.main, 0.1),
                              color: theme.palette.info.main,
                              marginRight: 1,
                            }}
                            label="Active"
                          />
                        )}
                        <Chip
                          size="small"
                          sx={{
                            backgroundColor: renderStatusColor(survey).backgroundColor,
                            color: renderStatusColor(survey).color,
                            textTransform: "capitalize",
                            fontSize: 12,
                          }}
                          label={`${survey?.status}`}
                        />
                      </ListItemButton>
                    )
                  })}
              </List>
            </Box>
          </>
        )}
      </Container>
    </>
  )
}

export default SelectSurveytList
