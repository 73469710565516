import { Relation, Model } from "@nozbe/watermelondb"
import { field, readonly, date, children, relation } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"
import type { Associations } from "@nozbe/watermelondb/Model"
import type Feature from "./Feature"
import type Form from "./Form"
import type WorkType from "./WorkType"
import type Project from "./Project"

export default class Survey extends Model {
  static table = TableName.Survey
  static associations: Associations = {
    [TableName.Form]: { type: "belongs_to", key: "formKey" },
    [TableName.WorkType]: { type: "belongs_to", key: "workTypeKey" },
    [TableName.Project]: { type: "belongs_to", key: "projectKey" },
    [TableName.Feature]: { type: "has_many", foreignKey: "surveyKey" },
  }

  @field("surveyId") surveyId!: number
  @field("surveyName") surveyName?: string
  @field("surveyDescription") surveyDescription?: string
  @field("projectId") projectId?: number
  @field("projectKey") projectKey?: string
  @field("status") status?: string
  @field("formKey") formKey?: string
  @field("formId") formId?: number
  @field("formData") formData?: string
  @field("formTitle") formTitle?: string
  @field("workTypeName") workTypeName?: string
  @field("workTypeKey") workTypeKey?: string
  @field("workTypeId") workTypeId?: number

  @readonly @date("created_at") createdAt!: number
  @field("createdBy") createdBy?: string
  @field("createdOffset") createdOffset?: number

  @readonly @date("updated_at") updatedAt?: number
  @field("updatedBy") updatedBy?: string
  @field("updatedOffset") updatedOffset?: number

  @field("deletedAt") deletedAt?: number
  @field("deletedBy") deletedBy?: string
  @field("deletedOffset") deletedOffset?: number

  @field("surveyFormStatus") surveyFormStatus?: string

  @children(TableName.Feature) features?: Feature[]

  @relation(TableName.Project, "projectKey") project?: Relation<Project>
  @relation(TableName.Form, "formKey") form?: Relation<Form>
  @relation(TableName.WorkType, "workTypeKey") workType?: Relation<WorkType>
}
