import type { FieldDataOptions, IFormTemplate, IWorkType } from "../interfaces"
import { Q } from "@nozbe/watermelondb"
import { database } from "../model/database"
import type Form from "../model/Form"
import { TableName } from "../model/schema"
import type WorkType from "../model/WorkType"
import type { IFormStyled } from "../interfaces/formResponse.interface"
import type LineStyle from "../model/LineStyle"
import type MapSymbol from "../model/MapSymbol"
import type Category from "../model/Category"
import { surveyService } from "./survey.service"
import { projectService } from "./project.service"
import { featureService } from "./feature.service"

class FormService {
  async getForms(projectId?: number): Promise<IFormTemplate[]> {
    let query = database.collections.get<Form>(TableName.Form).query(Q.where("deletedAt", Q.eq(null)))
    if (projectId) {
      query = query.extend(Q.where("projectId", projectId))
    }
    const forms = await query.fetch()
    const result = await Promise.all(
      forms.map(async (form) => {
        var f = this.mapForm(form)
        const workType = await form.workType?.fetch()
        f.workTypeName = workType?.workTypeName
        f.workTypeId = workType?.workTypeId

        return f
      }),
    )
    return result
  }

  async search(searchText: string, workTypeId: number, category: string, projectId: number): Promise<IFormTemplate[]> {
    let query = database.collections.get<Form>(TableName.Form).query(Q.where("deletedAt", Q.eq(null)))

    if (projectId) {
      query = query.extend(Q.where("projectId", projectId))
    }

    if (category && category.toString().toLowerCase() !== "all") {
      query = query.extend(Q.where("category", category))
    }

    if (workTypeId) {
      query = query.extend(Q.where("workTypeId", workTypeId))
    }

    if (searchText) {
      query = query.extend(Q.where("featureName", Q.like(`%${Q.sanitizeLikeString(searchText)}%`)))
    }

    const forms = await query.fetch()
    const result = await Promise.all(forms.map(async (form) => this.mapForm(form)))
    return result
  }

  async getOptions(path: string, projectId: number): Promise<FieldDataOptions> {
    if (path.includes("survey")) {
      // Assuming surveyService is injected or accessible
      return surveyService.getOptions(projectId)
    } else if (path.includes("project")) {
      return projectService.getOptions(projectId)
    } else if (path.includes("feature")) {
      return featureService.getOptions(projectId)
    }

    let query = database.collections.get<Form>(TableName.Form).query(Q.where("deletedAt", Q.eq(null)))

    if (projectId) {
      query = query.extend(Q.where("projectId", projectId))
    }

    const forms = await query.fetch()
    // const result = await Promise.all(forms.map(async (form) => this.mapForm(form)))
    return forms.reduce((acc, form) => {
      // Assuming form has id, name, and description fields
      acc[form.id] = {
        value: form.id.toString(),
        name: form.formTitle ?? "",
        description: form.formDescription,
        pkey: form.formId,
      }
      return acc
    }, {} as FieldDataOptions)
  }

  async getForm(id: number): Promise<IFormTemplate> {
    const form = await database.collections.get<Form>(TableName.Form).query(Q.where("formId", id)).fetch()
    if (form.length === 0) {
      throw new Error("Form not found")
    }
    return this.mapForm(form[0])
  }

  async getFormsStyled(projectId: number): Promise<IFormStyled[]> {
    const formsCollection = database.collections.get<Form>(TableName.Form)
    const workTypesCollection = database.collections.get<WorkType>(TableName.WorkType)
    const mapSymbolsCollection = database.collections.get<MapSymbol>(TableName.MapSymbol)
    const lineStylesCollection = database.collections.get<LineStyle>(TableName.LineStyle)

    const query = formsCollection.query(
      Q.where("deletedAt", Q.eq(null)),
      Q.experimentalNestedJoin(TableName.Feature, TableName.Survey),
      Q.on(TableName.Feature, Q.on(TableName.Survey, "projectId", projectId)),
    )
    // const query = formsCollection.query(Q.on("features", Q.on("surveys", Q.where("projectId", projectId))))
    const forms = await query.fetch()

    const result = await Promise.all(
      forms.map(async (form) => {
        let workType: WorkType | undefined
        if (form.workTypeKey !== undefined && form.workTypeKey !== null && form.workTypeKey !== "") {
          workType = await workTypesCollection.find(form.workTypeKey ?? "")
        }

        let mapSymbol: MapSymbol | undefined
        if (form.mapSymbolKey !== undefined && form.mapSymbolKey !== null && form.mapSymbolKey !== "") {
          mapSymbol = await mapSymbolsCollection.find(form.mapSymbolKey ?? "")
        }

        let lineStyle: LineStyle | undefined
        if (form.lineStyleKey !== undefined && form.lineStyleKey !== null && form.lineStyleKey !== "") {
          lineStyle = await lineStylesCollection.find(form.lineStyleKey ?? "")
        }

        return {
          ...this.mapForm(form),
          workTypeName: workType?.workTypeName,
          icon: mapSymbol?.icon,
          iconAnchor: mapSymbol?.iconAnchor,
          iconSize: mapSymbol?.iconSize,
          mapSymbolName: mapSymbol?.mapSymbolName,
          iconColor: form.mapSymbolColor,
          lineStyleId: lineStyle?.lineStyleId,
          lineStyleName: lineStyle?.lineStyleName,
          color: lineStyle?.color,
          dashArray: lineStyle?.dashArray,
          dashOffset: lineStyle?.dashOffset,
          lineCap: lineStyle?.lineCap,
          weight: lineStyle?.weight,
        }
      }),
    )

    return result.sort((a, b) => a.formTitle.localeCompare(b.formTitle))
  }

  private mapForm(form: Form): IFormTemplate {
    return {
      formId: form.formId,
      formTitle: form.formTitle ?? "",
      formDescription: form.formDescription ?? "",
      category: form.category,
      template: form.template,
      typeId: form.typeId,
      projectId: form.projectId,
      userId: form.userId,
      id: form.id,
      workTypeId: form.workTypeId,
      workTypeKey: form.workTypeKey,
      mapSymbolId: form.mapSymbolId,
      mapSymbolKey: form.mapSymbolKey,
      lineStyleId: form.lineStyleId,
      lineStyleKey: form.lineStyleKey,
      featureTypes: form.featureTypes,
      createdAt: form.createdAt,
      createdBy: form.createdBy,
      createdOffset: form.createdOffset,
      updatedAt: form.updatedAt,
      updatedBy: form.updatedBy,
      updatedOffset: form.updatedOffset,
      deletedAt: form.deletedAt,
      deletedBy: form.deletedBy,
      deletedOffset: form.deletedOffset,
    }
  }

  private mapWorkType(workType: WorkType): IWorkType {
    return {
      workTypeId: workType.workTypeId,
      workTypeName: workType.workTypeName,
      icon: workType.icon,
      locationRequired: workType.locationRequired,
      locationDisabled: workType.locationDisabled,
      locationHidden: workType.locationHidden,
      createdAt: workType.createdAt,
      createdBy: workType.createdBy,
      createdOffset: workType.createdOffset,
      updatedAt: workType.updatedAt,
      updatedBy: workType.updatedBy,
      updatedOffset: workType.updatedOffset,
      deletedAt: workType.deletedAt ? workType.deletedAt.getTime() : undefined,
      deletedBy: workType.deletedBy,
      deletedOffset: workType.deletedOffset,
    }
  }
}

export const formService = new FormService()
