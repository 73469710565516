import type { OverridableStringUnion } from "@mui/types"
import type { BadgePropsColorOverrides } from "@mui/material"
import type { GeoJsonObject } from "geojson"
import * as turf from "@turf/turf"
import type { IFormAttachment, IFormTemplate } from "../interfaces/formResponse.interface"
import type { IFeatureFilterStatus } from "../interfaces/survey.interface"

import type { IFeature, IFeatureStatus, IFeatureStyled } from "../interfaces/feature.interface"
import { green, grey, red } from "@mui/material/colors"
import { Q } from "@nozbe/watermelondb"
import { database } from "../model/database"
import type Feature from "../model/Feature"
import { TableName } from "../model/schema"
import type Attachment from "../model/Attachment"
import dayjs from "dayjs"
import { updateWatermelonDBObject } from "../utils/data.util"
import { FormDataParser } from "../utils/formDataParser.util"
import type Point from "../model/Point"
import type Form from "../model/Form"
import type FeatureEvent from "../model/FeatureEvent"
import { BASE_API_URL } from "../constants"
import { apiService } from "./api.service"
import type { FieldDataOptions } from "../interfaces"
import type { FeatureCollection } from "@turf/turf"
import {
  WifiTethering,
  AccessTime,
  PlayCircle,
  PauseCircle,
  Archive,
  ListAltOutlined,
  BlockOutlined,
} from "@mui/icons-material"

type PointGroup = {
  points: Point[]
  lastRestartEvent: number
}

class FeatureService {
  async getFeatures(projectId: number, surveyKey?: string): Promise<IFeature[]> {
    let query = database.collections
      .get<Feature>(TableName.Feature)
      .query(Q.where("projectId", projectId), Q.where("deletedAt", Q.eq(null)))
    if (surveyKey) {
      query = query.extend(Q.where("surveyKey", surveyKey))
    }
    const features = await query.fetch()
    return features.map((feature) => this.mapFeature(feature))
  }

  async search(arg: {
    time?: string
    projectId?: number
    searchText?: string
    formId?: number
    surveyKey?: string
    status?: IFeatureFilterStatus
    user?: string
  }): Promise<IFeatureStyled[]> {
    let query = database.collections.get<Feature>(TableName.Feature).query(Q.where("deletedAt", Q.eq(null)))
    //.query(Q.where("deletedAt", null))

    if (arg.projectId) {
      query = query.extend(Q.on(TableName.Survey, "projectId", arg.projectId))
    }

    if (arg.time && arg.time !== "all" && arg.time !== "All") {
      const offset = dayjs().utcOffset() * 60

      if (arg.time === "today") {
        const startOfDay = dayjs().startOf("day").valueOf() - offset
        const endOfDay = dayjs().endOf("day").valueOf() - offset
        query = query.extend(Q.where("created_at", Q.between(startOfDay, endOfDay)))
      } else if (arg.time === "yesterday") {
        const startOfDay = dayjs().subtract(1, "day").startOf("day").valueOf() - offset
        const endOfDay = dayjs().subtract(1, "day").endOf("day").valueOf() - offset
        query = query.extend(Q.where("created_at", Q.between(startOfDay, endOfDay)))
      } else if (arg.time === "last-week") {
        const startOfLastWeek = dayjs().subtract(1, "week").startOf("week").valueOf() - offset
        const endOfLastWeek = dayjs().subtract(1, "week").endOf("week").valueOf() - offset
        query = query.extend(Q.where("created_at", Q.between(startOfLastWeek, endOfLastWeek)))
      } else {
        const dates = arg.time.split(",")
        if (dates.length === 2) {
          const startDate = dayjs(dates[0]).startOf("day").valueOf() - offset
          const endDate = dayjs(dates[1]).endOf("day").valueOf() - offset
          query = query.extend(Q.where("created_at", Q.between(startDate, endDate)))
        } else if (dates.length === 1) {
          const startDate = dayjs(dates[0]).startOf("day").valueOf() - offset
          const endDate = dayjs(dates[0]).endOf("day").valueOf() - offset
          query = query.extend(Q.where("created_at", Q.between(startDate, endDate)))
        }
      }
    }

    if (arg.searchText) {
      query = query.extend(Q.where("featureName", Q.like(`%${Q.sanitizeLikeString(arg.searchText)}%`)))
    }

    if (arg.surveyKey !== undefined && arg.surveyKey !== null && arg.surveyKey !== "" && arg.surveyKey !== "all") {
      query = query.extend(Q.where("surveyKey", arg.surveyKey))
    }
    if (arg.formId !== undefined && arg.formId !== null && arg.formId !== -1) {
      query = query.extend(Q.where("formId", arg.formId))
    }
    if (arg.status && arg.status !== "all") {
      if (arg.status === "in progress") {
        query = query.extend(Q.where("status", Q.oneOf(["waiting", "started", "paused"])))
      } else {
        query = query.extend(Q.where("status", arg.status))
      }
    }
    if (arg.user && arg.user !== "all") {
      query = query.extend(Q.where("createdBy", arg.user))
    }

    const features = await query.fetch()
    const result = await Promise.all(
      features.map(async (feature) => {
        const iFeature = this.mapFeatureStyled(feature)
        const pointCount = await feature.points.count
        const fileCount = await feature.attachments.count
        const mapSymbol = await feature.mapSymbol?.fetch()
        const lineStyle = await feature.lineStyle?.fetch()
        iFeature.pointCount = pointCount
        iFeature.fileCount = fileCount
        iFeature.icon = mapSymbol?.icon ?? ""
        iFeature.iconColor = mapSymbol?.iconColor ? mapSymbol?.iconColor : (lineStyle?.color ?? "#3388ff")
        iFeature.iconSize = mapSymbol?.iconSize ?? ""

        iFeature.color = lineStyle?.color ?? "#3388ff"
        iFeature.weight = lineStyle?.weight ?? "normal"
        iFeature.opacity = lineStyle?.opacity ?? "1"

        if (!iFeature.icon?.toLowerCase().includes("http") && !iFeature.icon?.toLowerCase().includes("svg")) {
          iFeature.icon = `${BASE_API_URL}/map/icon?id=${iFeature?.mapSymbolId ?? 0}&color=${iFeature.iconColor?.replace("#", "")}`
        }

        return iFeature
      }),
    )

    return result
  }

  async getFeatureById(id: number): Promise<IFeatureStyled> {
    const features = await database.collections.get<Feature>(TableName.Feature).query(Q.where("featureId", id)).fetch()
    if (features.length === 0) {
      throw new Error("Feature not found")
    }

    const result = await Promise.all(
      features.map(async (feature) => {
        const iFeature = this.mapFeatureStyled(feature)
        const pointCount = await feature.points.count
        const fileCount = await feature.attachments.count
        const mapSymbol = await feature.mapSymbol?.fetch()
        const lineStyle = await feature.lineStyle?.fetch()
        iFeature.pointCount = pointCount
        iFeature.fileCount = fileCount
        iFeature.icon = mapSymbol?.icon ?? ""
        iFeature.iconColor = mapSymbol?.iconColor ? mapSymbol?.iconColor : (lineStyle?.color ?? "#3388ff")
        iFeature.iconSize = mapSymbol?.iconSize ?? ""

        iFeature.color = lineStyle?.color ?? "#3388ff"
        iFeature.weight = lineStyle?.weight ?? "normal"
        iFeature.opacity = lineStyle?.opacity ?? "1"

        if (!iFeature.icon?.toLowerCase().includes("http") && !iFeature.icon?.toLowerCase().includes("svg")) {
          iFeature.icon = `${BASE_API_URL}/map/icon?id=${iFeature?.mapSymbolId ?? 0}&color=${iFeature.iconColor?.replace("#", "")}`
        }
        return iFeature
      }),
    )

    return result[0]
  }

  async getFeature(key: string): Promise<IFeatureStyled> {
    const feature = await database.collections.get<Feature>(TableName.Feature).find(key)

    const iFeature = this.mapFeatureStyled(feature)
    const pointCount = await feature.points.count
    const fileCount = await feature.attachments.count
    const mapSymbol = await feature.mapSymbol?.fetch()
    const lineStyle = await feature.lineStyle?.fetch()
    iFeature.pointCount = pointCount
    iFeature.fileCount = fileCount
    iFeature.icon = mapSymbol?.icon ?? ""
    iFeature.iconColor = mapSymbol?.iconColor ? mapSymbol?.iconColor : (lineStyle?.color ?? "#3388ff")
    iFeature.iconSize = mapSymbol?.iconSize ?? ""
    iFeature.iconAnchor = mapSymbol?.iconAnchor ?? "[0, 0]"
    iFeature.popupAnchor = mapSymbol?.popupAnchor ?? "[0, 0]"

    iFeature.color = lineStyle?.color ?? "#3388ff"
    iFeature.weight = lineStyle?.weight ?? "normal"
    iFeature.opacity = lineStyle?.opacity ?? "1"

    if (!iFeature.icon?.toLowerCase().includes("http") && !iFeature.icon?.toLowerCase().includes("svg")) {
      iFeature.icon = `${BASE_API_URL}/map/icon?id=${iFeature?.mapSymbolId ?? 0}&color=${iFeature.iconColor?.replace("#", "")}`
    }
    return iFeature
  }

  async getOptions(projectId: number): Promise<FieldDataOptions> {
    let query = database.collections.get<Feature>(TableName.Feature).query(Q.where("deletedAt", Q.eq(null)))

    if (projectId) {
      query = query.extend(Q.on(TableName.Survey, "projectId", projectId))
    }

    const features = await query.fetch()

    return features.reduce((acc, feature) => {
      // Assuming feature has id, name, and description fields
      acc[feature.id] = {
        value: feature.id.toString(),
        name: feature.featureName ?? "",
        description: feature.featureDescription,
        pkey: feature.featureId,
      }
      return acc
    }, {} as FieldDataOptions)
  }

  processFeature(p: IFeatureStyled | IFeatureStyled[]): GeoJsonObject | null {
    const processSingleFeature = (featureStyled: IFeatureStyled): GeoJsonObject | null => {
      const geoJson = JSON.parse(featureStyled.geoJson ?? "{}")
      if (
        !geoJson.geometry ||
        !geoJson.geometry.coordinates ||
        (geoJson.geometry.coordinates && geoJson.geometry.coordinates.length === 0)
      ) {
        return null
      }
      const displayProps = FormDataParser.parseFormData(featureStyled?.formData ?? "")
      return {
        ...geoJson,
        properties: {
          color: featureStyled.color ?? "#3388ff",
          weight: featureStyled.weight ?? 2,
          opacity: featureStyled.opacity ?? 1,
          dashArray: featureStyled.dashArray ?? null,
          Icon: featureStyled.icon,
          IconColor: featureStyled.iconColor,
          displayProps: {
            "Feature Name": featureStyled.featureName ?? "",
            "Feature Date": dayjs((featureStyled.createdAt ?? 0) * 1000).format("MM/DD/YYYY hh:mm:ss A"),
            "Surveyed By": featureStyled.createdBy ?? null,
            ...displayProps,
          },
        },
      }
    }

    if (Array.isArray(p)) {
      const features = p.filter((feature) => feature.geoJson).map(processSingleFeature)

      return {
        type: "FeatureCollection",
        // @ts-ignore
        features: features,
      }
    } else {
      if (p.geoJson) {
        return processSingleFeature(p)
      } else {
        return {} as GeoJsonObject
      }
    }
  }

  async getFeaturesStyled(payload: {
    projectId?: number
    featureKey?: string
    surveyKey?: string
  }): Promise<IFeatureStyled[]> {
    let query = database.collections.get<Feature>(TableName.Feature).query(Q.where("deletedAt", Q.eq(null)))
    if (payload.projectId) {
      query = query.extend(Q.on(TableName.Survey, "projectId", payload.projectId))
    }
    if (payload.featureKey) {
      query = query.extend(Q.where("id", payload.featureKey))
    }
    if (payload.surveyKey) {
      query = query.extend(Q.where("surveyKey", payload.surveyKey))
    }

    const features = await query.fetch()
    return features.map((feature) => this.mapFeatureStyled(feature))
  }

  async getUsers(projectId: number): Promise<string[]> {
    const query = database.collections
      .get<Feature>(TableName.Feature)
      .query(Q.where("deletedAt", Q.eq(null)), Q.on(TableName.Survey, "projectId", projectId))
    const features = await query.fetch()
    const users = features.map((p) => p.createdBy ?? "")

    return Array.from(new Set(users))
  }

  async getFeatureAttachment(featureId: string, attachmentId: number): Promise<IFormAttachment[]> {
    const url = BASE_API_URL + `/feature/attachments`

    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
      params: { featureId, attachmentId },
    })

    const { data } = response
    console.log(data)
    return data
  }

  getFeatureAttachmentURL(featureId: string, attachmentId: number): string {
    const url = BASE_API_URL + `/feature/attachment`

    return `${url}?featureId=${featureId}&attachmentId=${attachmentId}`
  }

  async getFeaturesQuickAdds(payload: { projectId?: number; surveyKey?: string }): Promise<IFeatureStyled[]> {
    let query = database.collections
      .get<Feature>(TableName.Feature)
      .query(Q.where("deletedAt", Q.eq(null)), Q.where("projectId", payload.projectId || 0))
    if (payload.surveyKey) {
      query = query.extend(Q.where("surveyKey", payload.surveyKey))
    }

    const features = await query.fetch()
    return features.map((feature) => this.mapFeatureStyled(feature))
  }

  async getFeatureImages(featureId: string): Promise<IFormAttachment[]> {
    const attachments = database.collections
      .get<Attachment>(TableName.Attachment)
      .query(Q.where("path", Q.like(`Features/${featureId}`)))
      .fetch()

    return attachments || []
  }

  async addFeature(payload: IFeatureStyled, form?: IFormTemplate): Promise<IFeatureStyled> {
    let featureStyled: IFeatureStyled | undefined

    await database.write(async () => {
      const newFeature = await database.collections.get<Feature>(TableName.Feature).create((feature) => {
        updateWatermelonDBObject(feature, payload)
        if (form) {
          updateWatermelonDBObject(feature, form)
        }
        feature.formId = form ? form.formId : payload.formId
        feature.formKey = form && form.id ? form.id : (payload.formKey ?? "")
        feature.formData = form?.template
        feature.createdBy = payload.createdBy ?? ""
      })

      if (newFeature) {
        featureStyled = this.mapFeatureStyled(newFeature)
      }
    })

    if (!featureStyled) {
      throw new Error("Failed to create feature")
    }

    return featureStyled
  }

  async updateFeature(payload: IFeature): Promise<IFeature> {
    await database.write(async () => {
      const feature = await database.collections.get<Feature>(TableName.Feature).find(payload.id ?? "")
      await feature.update((feature) => {
        updateWatermelonDBObject(feature, payload)
      })
    })
    return payload
  }

  async updateFeatureStatus(id?: string, status?: IFeatureStatus): Promise<any> {
    if (!id || !status) {
      throw new Error("Invalid parameters")
    }
    const updatedFeature = await database.write(async () => {
      const featureToUpdate = await database.collections.get<Feature>(TableName.Feature).find(id)
      if (featureToUpdate === null) {
        throw new Error("Feature not found")
      }
      return featureToUpdate.update((record) => {
        record.status = status
      })
    })
    return updatedFeature
  }
  async updateFeatureFormData(id?: string, formData?: string): Promise<any> {
    if (!id || !formData) {
      throw new Error("Invalid parameters")
    }
    const updatedFeature = await database.write(async () => {
      const featureToUpdate = await database.collections.get<Feature>(TableName.Feature).find(id)
      if (featureToUpdate === null) {
        throw new Error("Feature not found")
      }
      return featureToUpdate.update((record) => {
        record.formData = formData
      })
    })
    return updatedFeature
  }

  async deleteFeature(id: string): Promise<string> {
    const feature = await database.collections.get<Feature>(TableName.Feature).find(id)
    if (feature === null) {
      throw new Error("Feature not found")
    }
    await feature.markAsDeleted()
    return "deleted"
  }

  async generateGeometries(featureKey: string): Promise<GeoJsonObject> {
    const pointsCollection = database.collections.get<Point>(TableName.Point)
    const formsCollection = database.collections.get<Form>(TableName.Form)
    const featureEventsCollection = database.collections.get<FeatureEvent>(TableName.FeatureEvent)

    // Fetch points
    const points = await pointsCollection.query(Q.where("featureKey", featureKey)).fetch()

    // Early return if no points exist
    if (!points.length) {
      // Return a minimal GeoJSON object with null geometry
      return {
        type: "Feature",
        geometry: null,
        properties: {
          isEmpty: true, // Add flag to indicate empty state
          color: "#3388ff",
          weight: 2,
          opacity: 1,
          dashArray: null,
          displayProps: {
            "Feature Name": "",
            "Feature Date": dayjs(Date.now()).format("MM/DD/YYYY hh:mm:ss A"),
            "Surveyed By": null,
          },
        },
      }
    }

    // Fetch feature and form
    const feature = await this.getFeature(featureKey)
    const form = await formsCollection.find(feature.formKey ?? "")

    // Fetch relevant feature events
    const featureEvents = await featureEventsCollection
      .query(Q.where("featureKey", featureKey), Q.where("eventType", Q.oneOf(["start", "end"])))
      .fetch()

    // Process points
    const processedPoints = points.filter((point) => point.latitude !== null && point.longitude !== null)

    // Additional validation for empty processed points
    if (!processedPoints.length) {
      return {
        type: "Feature",
        geometry: null,
        properties: {
          isEmpty: true,
          color: feature.color ?? "#3388ff",
          weight: feature.weight ?? 2,
          opacity: feature.opacity ?? 1,
          dashArray: feature.dashArray ?? null,
          Icon: feature.icon,
          IconColor: feature.iconColor,
          displayProps: {
            "Feature Name": feature.featureName ?? "",
            "Feature Date": dayjs((feature.createdAt ?? 0) * 1000).format("MM/DD/YYYY hh:mm:ss A"),
            "Surveyed By": feature.createdBy ?? null,
            ...FormDataParser.parseFormData(feature.formData ?? ""),
          },
        },
      }
    }

    // Sort points by createdAt
    processedPoints.sort((a, b) => a.createdAt - b.createdAt)

    // Function to find the last restart event before a given timestamp
    const findLastRestartEvent = (timestamp: number) => {
      return (
        featureEvents.filter((event) => event.createdAt < timestamp).sort((a, b) => b.createdAt - a.createdAt)[0]
          ?.createdAt || 1700000000
      )
    }

    // Group points based on feature events
    const groupedPoints: PointGroup[] = processedPoints.reduce((acc: PointGroup[], point) => {
      const lastRestartEvent = findLastRestartEvent(point.createdAt)
      const groupIndex =
        form.featureTypes === "Point"
          ? acc.length
          : acc.findIndex((group) => group.lastRestartEvent === lastRestartEvent)

      if (groupIndex === -1 || (form.featureTypes === "Point" && acc.length === 0)) {
        acc.push({ points: [point], lastRestartEvent })
      } else {
        acc[groupIndex].points.push(point)
      }
      return acc
    }, [])

    // Generate geometry
    let geometry: turf.AllGeoJSON
    try {
      switch (form.featureTypes) {
        case "Point":
          geometry = turf.multiPoint(processedPoints.map((p) => [p.longitude, p.latitude] as [number, number]))
          break
        case "Line":
          geometry = turf.multiLineString(
            groupedPoints.map((group) => group.points.map((p) => [p.longitude, p.latitude] as [number, number])),
          )
          break
        case "Polygon":
          geometry = turf.multiPolygon(
            groupedPoints.map((group) => {
              const coords = group.points.map((p) => [p.longitude, p.latitude] as [number, number])
              if (coords.length > 2) {
                coords.push(coords[0]) // Close the polygon
              }
              return [coords]
            }),
          )
          break
        default:
          throw new Error(`Unsupported feature type: ${form.featureTypes}`)
      }
    } catch (error) {
      console.error("Error generating geometry:", error)
      return {
        type: "Feature",
        geometry: null,
        properties: {
          isEmpty: true,
          error: true,
          color: feature.color ?? "#3388ff",
          // ... rest of the properties
        },
      }
    }

    // Update feature with new geometry
    // await database.write(async () => {
    //   await feature.update((featureFields) => {
    //     featureFields.geom = geometry
    //   })
    // })

    const geoJsonObject: GeoJsonObject = {
      type: "Feature",
      geometry: geometry.geometry,
      properties: {
        color: feature.color ?? "#3388ff",
        weight: feature.weight ?? 2,
        opacity: feature.opacity ?? 1,
        dashArray: feature.dashArray ?? null,
        Icon: feature.icon,
        IconColor: feature.iconColor,
        displayProps: {
          "Feature Name": feature.featureName ?? "",
          "Feature Date": dayjs((feature.createdAt ?? 0) * 1000).format("MM/DD/YYYY hh:mm:ss A"),
          "Surveyed By": feature.createdBy ?? null,
          ...FormDataParser.parseFormData(feature.formData ?? ""),
        },
      },
    }

    return geoJsonObject
  }

  async getProperties(arg: {
    featureId: number
    time?: string | Date
    projectId?: number
    searchText?: string
    formId?: number
    status?: IFeatureFilterStatus
    user?: string
    surveyId?: number
  }): Promise<FeatureCollection> {
    // Query for a specific feature
    const feature = await database.collections
      .get<Feature>(TableName.Feature)
      .query(Q.where("featureId", arg.featureId))
      .fetch()

    if (!feature || feature.length === 0) {
      throw new Error("Feature not found")
    }

    const featureData = feature[0]

    // Get related data
    const survey = await featureData.survey?.fetch()
    const project = await survey?.project?.fetch()

    // Create GeoJSON feature properties with grouped structure
    const geoJsonFeature = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: JSON.parse(featureData.geoJson ?? "{}")?.geometry ?? null,
          properties: {
            metadata: {
              "Feature Name": featureData.featureName ?? "",
              Description: featureData.featureDescription,
              Job: survey?.surveyName,
              Project: project?.projectName,
              Status: featureData.status,
              FeatureId: featureData.featureId,
              "Feature Created On": dayjs(featureData.createdAt * 1000).format("YYYY-MM-DD"),
              "Feature Created Time": dayjs(featureData.createdAt * 1000).format("HH:mm:ss"),
              "Feature Created By": featureData.createdBy,
            },
            formData: featureData.formData ? FormDataParser.parseFormData(featureData.formData) : {},
            // Note: Images would be handled through attachments if needed
          },
        },
      ],
    } as FeatureCollection

    return geoJsonFeature
  }

  getStatusColor(status: string | undefined, active: boolean | undefined): string {
    if (active) {
      return "#4fc3f7"
    }
    switch (status) {
      case "waiting":
        return "#aaa"
      case "started":
        return "#81c784"
      case "paused":
        return "#ffb74d"
      case "abandoned":
        return "#e57373"
      case "completed":
        return "#666"
      default:
        return "grey"
    }
  }

  getStatusThemeColor(
    status: string | undefined,
    active: boolean | undefined,
  ): OverridableStringUnion<
    "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning",
    BadgePropsColorOverrides
  > {
    if (active) {
      return "info"
    }
    switch (status) {
      case "waiting":
        return "default"
      case "started":
        return "success"
      case "paused":
        return "warning"
      case "abandoned":
        return "error"
      case "completed":
        return "secondary"
      default:
        return "default"
    }
  }

  getStatusThemeColorAlt(status: string | undefined): any {
    switch (status) {
      case "waiting":
        return {
          color: grey[800],
          backgroundColor: grey[100],
          borderColor: grey[500],
        }
      case "started":
        return {
          color: green[800],
          backgroundColor: green[50],
          borderColor: green[500],
        }
      case "paused":
        return {
          color: grey[800],
          backgroundColor: grey[50],
          borderColor: grey[100],
        }
      case "abandoned":
        return {
          color: red[800],
          backgroundColor: red[50],
          borderColor: red[500],
        }
      case "completed":
        return {
          color: grey[100],
          backgroundColor: grey[700],
          borderColor: grey[500],
        }
      default:
        return {
          color: grey[600],
          backgroundColor: grey[100],
          borderColor: grey[800],
        }
    }
  }

  getStatusIcon(status: string | undefined, active: boolean | undefined) {
    if (active) {
      return WifiTethering
    }
    switch (status) {
      case "waiting":
        return AccessTime
      case "started":
        return PlayCircle
      case "paused":
        return PauseCircle
      case "completed":
        return Archive
      default:
        return ListAltOutlined
    }
  }

  private mapFeature(feature: Feature): IFeature {
    return {
      featureId: feature.featureId,
      featureName: feature.featureName ?? "",
      featureDescription: feature.featureDescription,
      surveyId: feature.surveyId,
      surveyKey: feature.surveyKey,
      deviceId: feature.deviceId,
      formId: feature.formId,
      formData: feature.formData,
      id: feature.id,
      status: feature.status as IFeatureStatus,
      // featureCollection: feature.featureCollection,
      workTypeId: feature.workTypeId,
      workTypeName: feature.workTypeName,
      formTitle: feature.formTitle,
      createdAt: feature.createdAt,
      createdBy: feature.createdBy,
      createdOffset: feature.createdOffset,
      updatedAt: feature.updatedAt,
      updatedBy: feature.updatedBy,
      updatedOffset: feature.updatedOffset,
      deletedAt: feature.deletedAt,
      deletedBy: feature.deletedBy,
      deletedOffset: feature.deletedOffset,
    }
  }

  private mapFeatureStyled(feature: Feature): IFeatureStyled {
    return {
      ...this.mapFeature(feature),
      featureTypes: feature.featureTypes,
      surveyKey: feature.surveyKey,
      deviceKey: feature.deviceKey,
      formKey: feature.formKey,
      workTypeKey: feature.workTypeKey,
      mapSymbolId: feature.mapSymbolId,
      mapSymbolKey: feature.mapSymbolKey,
      weight: "normal",
      opacity: "1",
      lineCap: "round",
      lineJoin: "round",
      lineLabel: "",
      dashArray: "none",
      dashOffset: 0,
      thumbnail: "",
      shadowIcon: "",
      shadowSize: "",
      iconAnchor: "",
      shadowAnchor: "",
      popupAnchor: "",
      fileCount: 0,
      medias: [],
      geoJson: feature.geoJson,
    }
  }
}

export const featureService = new FeatureService()
